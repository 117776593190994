import React from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_4Spq514S6WZpbxWY0n2uts0q");

const getCheckoutSession = async (priceId, email_address) => {
  // Get Stripe.js instance
  const stripe = await stripePromise;
  const checkout_session_url =
    "http://localhost:5001/nestria-d07e4/us-central1/nestria/payments/create-checkout-session";
  // Call your backend to create the Checkout Session
  fetch(checkout_session_url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ priceId: priceId, email_address: "leon@test.com" }),
  })
    .then(async (response) => {
      const session = await response.json();
      if (session.status === "success") {
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: session.data.id,
        });
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
      } else {
        console.log(session.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const Checkout = () => {
  let location = useLocation();
  let query = new URLSearchParams(location.search);
  console.log(query.get("id"));

  let priceId = query.get("id");
  if (priceId) {
    getCheckoutSession(priceId, "");
  } else {
    // Page Invalid
  }

  return (
    <React.Fragment>
      <center>
        <h3>Hang tight!</h3>
      </center>
      <center> Redirecting you to Payment Gateway </center>
    </React.Fragment>
  );
};
