import React, { useState, useContext } from "react";
import { PersonalInfoForm } from "./PersonalInfoForm";
import { BackgroundInfoForm } from "./BackgroundInfoForm";
import { ContactInfoForm } from "./ContactInfoForm";
import { MembershipForm } from "./MembershipForm";
import {
  Box,
  Container,
  Grid,
  Stepper,
  StepLabel,
  Step,
  Button,
  CardMedia,
  Link,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import { Form, Formik } from "formik";
import hubspot from "../../utils/hubspot";
import { EmailContext } from "../../App";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardMedia: {
    width: "70px",
    height: "70px",
  },
  btns: {
    "&.MuiButton-contained": {
      backgroundColor: "black",
      color: "white",
    },
  },
  stepIcon: {
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "black",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "black",
    },
    "&.MuiStepper-root": {
      padding: "8px",
    },
  },
}));

const showStep = (
  step,
  values,
  errors,
  touched,
  handleBlur,
  setuserData,
  userData,
  setFieldValue
) => {
  switch (step) {
    case 1:
      return (
        <MembershipForm
          step={step}
          errors={errors}
          touched={touched}
          values={values}
          setuserData={setuserData}
          userData={userData}
          setFieldValue={setFieldValue}
        />
      );
    case 2:
      return (
        <PersonalInfoForm
          errors={errors}
          touched={touched}
          values={values}
          setuserData={setuserData}
          userData={userData}
          setFieldValue={setFieldValue}
        />
      );
    case 3:
      return (
        <BackgroundInfoForm
          errors={errors}
          touched={touched}
          values={values}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      );
    case 4:
      return <ContactInfoForm errors={errors} touched={touched} />;
    default:
      return <PersonalInfoForm errors={errors} touched={touched} />;
  }
};

export const MainForm = () => {
  const [step, setStep] = useState(1);
  const { value } = useContext(EmailContext);
  const [userData, setuserData] = useState({
    fname: "",
    lname: "",
    email: value ? value : "",
    phone: "",
    dob: null,
    gender: "",
    user_timezone: "",
    user_location: "",
    user_referred_by: "",
    how_user_heard_abt_nestria: "",
    current_job_title: "",
    experience: "",
    job_industry: "",
    tech_known: [],
    tech_to_learn: [],
    ideal_job_role: "",
    linkedin_username: "",
    discord_id: "",
    github_handle: "",
    membership: "",
  });

  const classes = useStyles();

  const validationSchema = () => {
    if (step === 1) {
      return yup.object().shape({
        membership: yup.string().required("Must select one option"),
      });
    } else if (step === 2) {
      return yup.object().shape({
        fname: yup.string().required("First Name is required").max(20),
        lname: yup.string().required("Last Name is required").max(20),
        email: yup
          .string()
          .email("Invalid email")
          .required("Email is required"),
      });
    } else if (step === 3) {
      return yup.object().shape({});
    } else {
      return yup.object().shape({});
    }
  };

  return (
    <React.Fragment>
      {/* <CssBaseline /> */}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Container>
          {userData.membership === "free" && step >= 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1em",
              }}
            >
              <Link href="/home" variant="body1" color="inherit">
                Skip &nbsp;
              </Link>
            </div>
          ) : null}
          <Grid container justify="center">
            <Grid item xs={10} md={8} lg={6}>
              <Box
                marginTop={2}
                marginBottom={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <CardMedia
                  component="img"
                  src={"/images/nestrialogo.png"}
                  title="Nestria"
                  className={classes.cardMedia}
                />
                <div>
                  <Stepper
                    alternativeLabel
                    activeStep={step - 1}
                    className={classes.stepIcon}
                  >
                    <Step>
                      <StepLabel>Membership Details</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>Personal Details</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>Background Details</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>Contact Details</StepLabel>
                    </Step>
                  </Stepper>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={{ ...userData }}
                  onSubmit={async (values, { setSubmitting }) => {
                    if (step === 4) {
                      setuserData(values);
                      setSubmitting(false);
                      hubspot.postFormData(values);
                      window.open("/payment", "_self");
                    } else {
                      setStep((s) => s + 1);
                    }
                  }}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    handleBlur,
                    submitForm,
                    setFieldValue,
                  }) => (
                    <Form className={classes.form}>
                      {showStep(
                        step,
                        values,
                        errors,
                        touched,
                        handleBlur,
                        setuserData,
                        userData,
                        setFieldValue,
                        isSubmitting
                      )}
                      <Grid container spacing={2} justify="center">
                        {step > 1 ? (
                          <Grid item>
                            <Button
                              variant="contained"
                              disabled={isSubmitting}
                              className={classes.btns}
                              onClick={() => setStep((s) => s - 1)}
                            >
                              Back
                            </Button>
                          </Grid>
                        ) : null}
                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting}
                            className={classes.btns}
                          >
                            {step === 4 ? "Submit" : "Next"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
};
