import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import { Hidden, Menu, MenuItem } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { FirebaseAuth } from "../instances/firebaseAuth";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  // position  avatar button  right side
  avatarButton: {
    position: "absolute",
    right: 8,
    top: 5,
  },
  avatar: {
    backgroundColor: "orange",
    padding: 10,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    color: "black",
    fontWeight: "bold",
  },
  navBtn: {
    "&.MuiButton-colorInherit": {
      margin: "10px",
      color: "black",
    },
  },
  toolbar: {
    "&.MuiToolbar-gutters": {
      paddingLeft: "30px",
      // paddingRight: "50px",
    },
  },
  appbar: {
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "white",
      boxShadow: "none",
      borderBottom: " 0.1px solid black",
    },
  },
  bottomNav: {
    color: "white",
    background:
      "linear-gradient(180deg, #1757FF 0%, #1757FF 0.01%, #6A91FD 83.85%, #6A91FD 100%)",
    position: "fixed",
    bottom: 0,
  },
  link: {
    "&.MuiTypography-colorPrimary": {
      color: "white",
      textDecoration: "none",
    },
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    width: "100%",
    maxWidth: "50px",
    height: "auto",
    paddingTop: 5,
    paddingBottom: 5,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  navTitle: {
    fontSize: 24,
    fontWeight: "bold",
  },
  nav: {
    paddingTop: 50,
    paddingRight: 40,
    textAlign: "center",
  },
}));

export const NavFooterComponent = () => {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        className={classes.nav}
        component="nav"
        aria-label="main mailbox folders"
      >
        <ListItem button>
          <ListItemText
            primary={
              <Typography type="body2" className={classes.navTitle}>
                Home
              </Typography>
            }
            href="#simple-list"
            onClick={() => window.open("/home", "_self")}
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            primary={
              <Typography type="body2" className={classes.navTitle}>
                Discord
              </Typography>
            }
            onClick={() => window.open("/discord", "_self")}
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            primary={
              <Typography type="body2" className={classes.navTitle}>
                Blogs
              </Typography>
            }
            onClick={() => window.open("/blog", "_self")}
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            primary={
              <Typography type="body2" className={classes.navTitle}>
                Bookmarks
              </Typography>
            }
            onClick={() => window.open("/bookmark", "_self")}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => window.open("https://www.notion.so/", "_blank")}
        >
          <ListItemText
            primary={
              <Typography type="Notion" className={classes.navTitle}>
                Notion
              </Typography>
            }
          />
        </ListItem>
        <ListItem button onClick={() => window.open("/interns", "_self")}>
          <ListItemText
            primary={
              <Typography type="Notion" className={classes.navTitle}>
                Internships
              </Typography>
            }
          />
        </ListItem>{" "}
        <ListItem button onClick={() => window.open("/workshops", "_self")}>
          <ListItemText
            primary={
              <Typography type="Notion" className={classes.navTitle}>
                Workshops
              </Typography>
            }
          />
        </ListItem>
        <div
          style={{
            paddingTop: 50,
          }}
        >
          {currentUser ? (
            <Button
              color="inherit"
              variant="outlined"
              size="small"
              style={{
                color: "black",
              }}
              onClick={handleLogout}
            >
              Log Out
            </Button>
          ) : null}
        </div>
      </List>
    </div>
  );

  const handleLogout = () => {
    FirebaseAuth()
      .signOut()
      .then(() => {
        enqueueSnackbar("Logout succesfull.", {
          variant: "success",
        });
        history.push("/");
      })
      .catch((err) => {
        enqueueSnackbar("Error occured while logging out", {
          variant: "error",
        });
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let loginbutton;
  if (currentUser) {
    loginbutton = (
      <div>
        <Button onClick={handleClick}>
          <Avatar src={currentUser.photoURL} />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={5}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h5" className={classes.title}>
            <Link href="/home" color="inherit" underline="none">
              <img
                className={classes.logo}
                alt="logo"
                src="\images\nestrialogo.png"
              />
            </Link>
          </Typography>
          <Hidden smDown>
            <Typography
              variant="body1"
              component="h2"
              className={classes.title}
            >
              <Link href="/home" color="inherit" underline="none">
                Home
              </Link>
            </Typography>
            <Typography
              variant="body1"
              component="h2"
              className={classes.title}
            >
              <Link href="/discord" color="inherit" underline="none">
                Discord
              </Link>
            </Typography>
            <Typography
              variant="body1"
              component="h2"
              className={classes.title}
            >
              <Link href="/blog" color="inherit" underline="none">
                Blogs
              </Link>
            </Typography>
            <Typography
              variant="body1"
              component="h2"
              className={classes.title}
            >
              <Link href="/bookmark" color="inherit" underline="none">
                Bookmarks
              </Link>
            </Typography>
            <Typography
              variant="body1"
              component="h2"
              className={classes.title}
            >
              <Link
                onClick={() => window.open("https://www.notion.so/", "_blank")}
                color="inherit"
                underline="none"
              >
                Notion
              </Link>
            </Typography>
            <Typography
              variant="body1"
              component="h2"
              className={classes.title}
            >
              <Link href="/interns" color="inherit" underline="none">
                Internships
              </Link>
            </Typography>{" "}
            <Typography
              variant="body1"
              component="h2"
              className={classes.title}
            >
              <Link href="/workshops" color="inherit" underline="none">
                Workshops
              </Link>
            </Typography>
          </Hidden>
          <div>{loginbutton}</div>
          <Hidden mdUp>
            <div>
              {
                <React.Fragment key={"right"}>
                  <Button onClick={toggleDrawer("right", true)}>
                    {" "}
                    <MenuIcon />
                  </Button>
                  <Drawer
                    anchor={"right"}
                    open={state["right"]}
                    onClose={toggleDrawer("right", false)}
                  >
                    {list("right")}
                  </Drawer>
                </React.Fragment>
              }
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
};
