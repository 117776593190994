import React from "react";
import { Field } from "formik";
import { TextField, RadioGroup } from "formik-material-ui";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import {
  Box,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TimezoneSelect from "react-timezone-select";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "100%",
  },
  field1: {
    width: "0%",
  },
}));

export const PersonalInfoForm = ({ ...props }) => {
  const classes = useStyles();

  return (
    <div>
      <Box paddingBottom={0.5}>
        <Field
          disabled={false}
          name="fname"
          label="First Name"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={0.5}>
        <Field
          disabled={false}
          name="lname"
          label="Last Name"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={0.5}>
        <Field
          disabled={false}
          name="email"
          label="Email"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={0.5}>
        <Field
          disabled={false}
          type="number"
          name="phone"
          label="Phone Number"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={0.5}>
        <Field
          disabled={false}
          component={KeyboardDatePicker}
          autoOk
          variant="inline"
          inputVariant="outlined"
          name="dob"
          disableFuture
          openTo="year"
          format="dd/MM/yyyy"
          label="D.O.B"
          placeholder="Date of birth"
          views={["year", "month", "date"]}
          InputAdornmentProps={{ position: "start" }}
          margin="dense"
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={0.5}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Gender</FormLabel>
          <Field component={RadioGroup} name="gender" label="Gender" row>
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Female"
            />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </Field>
        </FormControl>
      </Box>
      <Box paddingBottom={0.5}>
        <TimezoneSelect
          name="user_timezone"
          value={props.values.user_timezone}
          onChange={(e) => {
            props.setFieldValue("user_timezone", e.value);
          }}
          labelStyle="abbrev"
          className={classes.field}
          placeholder="Select Time Zone"
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 5 }),
          }}
        />
      </Box>
      <Box paddingBottom={0.5}>
        <Field
          disabled={false}
          name="user_location"
          label="Location"
          placeholder="Enter your city"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={0.5}>
        <Field
          disabled={false}
          name="user_referred_by"
          label="Who referred you?"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={0.5}>
        <Field
          disabled={false}
          name="how_user_heard_abt_nestria"
          label="How did you heard about us?"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
    </div>
  );
};
