import React from "react";
import Footer from "./Footer";
import { NavFooterComponent } from "./NavFooterComponent";
import { BlogsComponent } from "./BlogsComponent_old";
import { DiscordComponent } from "./DiscordComponent";
import { FeedComponent } from "./FeedComponent";
import { Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { DatalistComponent } from "./DatalistComponent";
import { WorkshopList } from "./WorkshopList";
import { InternCarousel } from "./InternCarousel";
import { WorkshopCarousel } from "./WorkshopCarousel";
import { FeaturedWorkshop } from "./FeaturedWorkshop";
import { BlogCarousel } from "./BlogCarousel";
import { Featurededucation } from "./Featurededucation";
import { Internshipbookmarked } from "./bookmark/internshipbookmark";
import { AddnewWorkshop } from "./AddnewWorkshop";

export const MemberFeed = () => {
  const location = useLocation();

  const showContent = () => {
    if (location.pathname === "/blog") {
      return <BlogsComponent />;
    } else if (location.pathname === "/discord") {
      return <DiscordComponent />;
    } else if (location.pathname === "/feed") {
      return <FeedComponent />;
    } else if (location.pathname === "/interns") {
      return <DatalistComponent />;
    } else if (location.pathname === "/workshops") {
      return <WorkshopList />;
    } else if (location.pathname === "/bookmark") {
      return <Internshipbookmarked />;
    } else if (location.pathname === "/addworkshop") {
      return <AddnewWorkshop />;
    } else {
      return (
        <div>
          <Grid container spacing={1}>
            <InternCarousel />
            <FeaturedWorkshop />
            <WorkshopCarousel />
            <Featurededucation />
            <BlogCarousel />
          </Grid>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          minHeight: "100vh",
          display: "block",
          position: "relative",
          paddingBottom: "380px",
        }}
      >
        <NavFooterComponent />
        {showContent()}
        <Footer />
      </div>
    </React.Fragment>
  );
};
