import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Box, CardMedia } from "@material-ui/core";
import { Container, Grid, Link } from "@material-ui/core";
import { BlogItem } from "../context/BlogLoader";
import moment from "moment";
import { config } from "../config/constants";
import { useSnackbar } from "notistack";

let link = config.url.API_URL + config.url.BLOG_URL;

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: "#F2F5F8",
    "&.MuiPaper-rounded": {
      borderRadius: "10px",
    },
  },
  heading: {
    "&.MuiTypography-body1": {
      fontWeight: "bold",
      lineHeight: "1.3",
    },
  },
  pos: {
    marginBottom: 12,
    "&.MuiTypography-body2": {
      fontSize: "0.8rem",
    },
  },
  container: {
    "&.MuiContainer-root": {
      marginBottom: "100px",
      paddingLeft: "16px",
      paddingRight: "8px",
    },
  },
});

export const BlogsComponent = () => {
  const classes = useStyles();
  const [posts, setposts] = useState([]);
  const [isLoaded, setisLoaded] = useState(false);
  const [error, seterror] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(link);
        if (response.status === 200) {
          const data = await response.json();
          const items = data.data;
          setposts(items);
          setisLoaded(true);
        } else {
          seterror(true);
          enqueueSnackbar("Error while loading discord data.", {
            variant: "error",
          });
        }
      } catch (err) {
        seterror(true);
        enqueueSnackbar("Error while loading Blog data.", {
          variant: "error",
        });
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Container className={classes.container}>
        <Box marginTop={0} maxHeight={600} position="relative" overflow="auto">
          <Typography variant="overline" display="block">
            Featured Blogs
          </Typography>
          <div>
            {error ? (
              <Box display="flex" justifyContent="center">
                Unable to load blog data.
              </Box>
            ) : (
              <div>
                {!isLoaded ? (
                  <BlogItem />
                ) : (
                  <div>
                    {posts
                      .slice(0, 5)
                      .map(
                        ({
                          id,
                          name,
                          publish_date,
                          post_summary,
                          featured_image,
                          absolute_url,
                        }) => (
                          <Box marginTop={2} textOverflow="auto" key={id}>
                            <Card className={classes.root} variant="outlined">
                              <Grid container spacing={0}>
                                <Grid item xs={8}>
                                  <CardContent>
                                    <Typography
                                      className={classes.heading}
                                      component="h6"
                                    >
                                      {name}
                                    </Typography>
                                    <Typography
                                      className={classes.pos}
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {moment({ publish_date }).format(
                                        "MMMM Do YYYY"
                                      )}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="p"
                                      color="textPrimary"
                                    >
                                      {post_summary
                                        ? post_summary
                                            .replace(/<[^>]+>/g, "")
                                            .slice(0, 50)
                                        : null}
                                      ...
                                      <br />
                                    </Typography>
                                  </CardContent>
                                </Grid>
                                <Grid item xs={4}>
                                  <div style={{ margin: "20px 10px" }}>
                                    <CardMedia
                                      component="img"
                                      src={
                                        featured_image ||
                                        "/images/developer-3461405_1280.png"
                                      }
                                      width="100"
                                      height="100"
                                      title={name}
                                    />
                                    <CardActions
                                      style={{
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Link
                                        href={absolute_url}
                                        underline="none"
                                        color="inherit"
                                        variant="caption"
                                      >
                                        Read more
                                      </Link>
                                    </CardActions>
                                  </div>
                                </Grid>
                              </Grid>
                            </Card>
                          </Box>
                        )
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Box>
      </Container>
    </React.Fragment>
  );
};
