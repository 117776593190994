import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Box, AppBar, Toolbar } from "@material-ui/core";

function Copyright() {
  const classes = useStyles();
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            float: "left",
          }}
        >
          <List
            component="nav"
            aria-label="main mailbox folders"
            className={classes.listItem}
          >
            <ListItem button>
              <ListItemText
                primary={<Typography type="body2">Home</Typography>}
                href="#simple-list"
                onClick={() => window.open("/", "_self")}
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                primary={<Typography type="body2">Discord</Typography>}
                onClick={() => window.open("/discord", "_self")}
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                primary={<Typography type="body2">Blogs</Typography>}
                onClick={() => window.open("/blog", "_self")}
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                primary={<Typography type="body2">Bookmarks</Typography>}
                onClick={() => window.open("/bookmark", "_self")}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => window.open("https://www.notion.so/", "_blank")}
            >
              <ListItemText
                primary={<Typography type="Notion">Notion</Typography>}
              />
            </ListItem>
          </List>
        </div>
        <div
          style={{
            float: "right",
            display: "flex",
            paddingLeft: 15,
          }}
        >
          <FacebookIcon />
          <Box p={1} />
          <TwitterIcon />
          <Box p={1} />

          <LinkedInIcon />
          <Box p={1} />

          <InstagramIcon />
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          paddingBottom: 10,
          paddingTop: 30,
        }}
      >
        <Typography variant="body2">
          {" © "}
          <Link color="inherit" href="https://material-ui.com/">
            Nestria
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  bottomNav: {
    color: "white",
    backgroundColor: "black",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  footerToolBar: {
    "&.MuiToolbar-root": {
      display: "block",
    },
    "&.MuiToolbar-gutters": {
      paddingLeft: "3em",
      paddingRight: "3em",
    },
  },
  listItem: {
    "& .MuiListItem-root": {
      width: "5%",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.bottomNav}>
      <Toolbar className={classes.footerToolBar}>
        <Copyright />
      </Toolbar>
    </AppBar>
  );
}
