import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Grid, Button, Modal } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import { Avatar, Box } from "@material-ui/core";
import { config } from "../config/constants";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useSnackbar } from "notistack";
import { EventsLoader } from "../context/Carousel";

var moment = require("moment");
require("moment-range");
require("moment-timezone");
let link = config.url.API_URL + config.url.JOB_URL;
let bookmark_api = config.url.API_URL + config.url.BOOKMARK_URL;

const datefilter = [
  "Today",
  "Last Day",
  "Last 3 Days",
  "Last Week",
  "Last 2 Weeks",
  "Last 4 Weeks",
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: "none",
    background: "rgb(250, 250, 250)",
    maxHeight: "600px",
    position: "relative",
    overflow: "auto",
    margin: 0,
  },
  container: {
    marginTop: "10px",
  },
  cardContent: {},
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  paper: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}));

function getModalStyle() {
  return {
    width: "100%",
    maxWidth: "100vw",
    maxHeight: "100%",
    position: "fixed",
    top: "50%",
    left: "0",
    transform: "translate(0, -50%)",
    overflowY: "auto",
  };
}

export const DatalistComponent = () => {
  const classes = useStyles();
  const [location, setLocation] = useState("");
  const [listoflocation, setListoflocation] = useState([]);
  const [data, setData] = useState([]);
  const [entiredata, setentireData] = useState([]);
  const [date, setDate] = useState("");
  const [open, setOpen] = useState(false);
  const [internshipIndex, setinternshipIndex] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const [checksave, setchecksave] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);
  const [error, seterror] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useContext(AuthContext);
  const [pageNo, setpageNo] = useState(1);
  

  useEffect(() => {
    fetchinternData();
    // eslint-disable-next-line
  }, []);

  function sortbydatetime(list) {
    list.sort((a, b) => b.date_published - a.date_published);
  }
  
   let page = 1;
  async function fetchinternData() {
    console.log("Page no :", pageNo, data, entiredata);
    await fetch(`${link}?limit=10&page=${pageNo}`)
      .then((response) => response.json())
      .then((res) => {
        const apidata = res.data;
       
        console.log("Api data in useEffect ::", apidata);
        if (data.length === 0 && entiredata.length === 0) {
        // sortbydatetime(apidata); 
        calculate_days_hours(apidata);
        setData(apidata);
        setentireData(apidata);
        sortlocation(apidata);
        setisLoaded(true);
        } else {
        
          setData((data) => [...data, apidata]);
          setentireData((entiredata) => [...entiredata, apidata]);
          sortbydatetime(data);
          calculate_days_hours(data);
          sortlocation(data);
          setisLoaded(true);
        }
      })
      .catch((err) => {
        seterror(true);
        enqueueSnackbar("Error while loading interns data.", {
          variant: "error",
        });
      });
  }

  function calculate_days_hours(list) {
    console.log("list",list)
    list.map((d) => {
      const timestampdatetime = d.date_published;
      const date = new Date(timestampdatetime * 1000);
      const timestamp = Date.now();
      var timezone = moment.tz.guess();
      const currentdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(timestamp);
      const timestampdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(date);
      var date_published = moment(timestampdate, "MM/DD/YYYY HH:mm:ss").tz(
        timezone
      );
      var currentdatetime = moment(currentdate, "MM/DD/YYYY HH:mm:ss").tz(
        timezone
      );
      var duration = moment.duration(
        moment(currentdatetime).diff(moment(date_published))
      );
      var days = duration.asDays();
      var hours = duration.asHours();
      if (days > 1) {
        d.date_published = `${Math.round(days)} days ago`;
      } else {
        d.date_published = `${Math.round(hours)} hours ago`;
      }
      return null;
    });
  }

  function sortlocation(list) {
    const uniqueLocation = [];
    list.map((d) => {
      d.company_location = d.company_location.trim();
      if (uniqueLocation.indexOf(d.company_location) === -1) {
        uniqueLocation.push(d.company_location);
      }

      return null;
    });
    setListoflocation(uniqueLocation);
    setLocation("All Cities");
  }

  function getfilteredData(event) {
    const c = event.target.value;
    setLocation(c);
    const arr = [];
    if (c === "All Cities") {
      setData(entiredata);
    } else {
      entiredata
        .filter((d) => d["company_location"] === c)
        .map((filteredData) => {
          arr.push(filteredData);
          setData(arr);
          return null;
        });
    }
  }

  function getfiltereddateData(event) {
    const d = event.target.value;
    setDate(event.target.value);
    const arr = [];

    if (d === "Last Day") {
      const d = entiredata.filter(
        (d) =>
          (d["date_published"].includes("days") &&
            d["date_published"].split(" ")[0] === 1) ||
          d["date_published"].includes("hours")
      ).length;
      if (d === 0) {
        setData(arr);
      } else {
        entiredata
          .filter(
            (d) =>
              (d["date_published"].includes("days") &&
                d["date_published"].split(" ")[0] === 1) ||
              d["date_published"].includes("hours")
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    } else if (d === "Posted Any Time") {
      setData(entiredata);
    } else if (d === "Today") {
      const d = entiredata.filter(
        (d) =>
          d["date_published"].includes("hours") ||
          d["date_published"].includes("hour")
      ).length;

      if (d === 0) {
        setData(arr);
      } else {
        entiredata
          .filter(
            (d) =>
              d["date_published"].includes("hours") ||
              d["date_published"].includes("hour")
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    } else if (d === "Last 3 Days") {
      const d = entiredata.filter(
        (d) =>
          (d["date_published"].includes("days") &&
            d["date_published"].split(" ")[0] <= 3) ||
          d["date_published"].includes("hours")
      ).length;

      if (d === 0) {
        setData(arr);
      } else {
        entiredata
          .filter(
            (d) =>
              (d["date_published"].includes("days") &&
                d["date_published"].split(" ")[0] <= 3) ||
              d["date_published"].includes("hours")
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    } else if (d === "Last Week") {
      const d = entiredata.filter(
        (d) =>
          (d["date_published"].includes("days") &&
            d["date_published"].split(" ")[0] <= 7) ||
          d["date_published"].includes("hours")
      ).length;

      if (d === 0) {
        setData(arr);
      } else {
        entiredata
          .filter(
            (d) =>
              (d["date_published"].includes("days") &&
                d["date_published"].split(" ")[0] <= 7) ||
              d["date_published"].includes("hours")
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    } else if (d === "Last 2 Weeks") {
      const d = entiredata.filter(
        (d) =>
          (d["date_published"].includes("days") &&
            d["date_published"].split(" ")[0] <= 14) ||
          d["date_published"].includes("hours")
      ).length;

      if (d === 0) {
        setData(arr);
      } else {
        entiredata
          .filter(
            (d) =>
              (d["date_published"].includes("days") &&
                d["date_published"].split(" ")[0] <= 14) ||
              d["date_published"].includes("hours")
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    } else if (d === "Last 4 Weeks") {
      const d = entiredata.filter(
        (d) =>
          (d["date_published"].includes("days") &&
            d["date_published"].split(" ")[0] <= 30) ||
          d["date_published"].includes("hours")
      ).length;

      if (d === 0) {
        setData(arr);
      } else {
        entiredata
          .filter(
            (d) =>
              (d["date_published"].includes("days") &&
                d["date_published"].split(" ")[0] <= 30) ||
              d["date_published"].includes("hours")
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    }
  }

  const checkSave = (data) => {
    if (data !== null) {
      // get all the bookmark interns/jobs and check for dulicate
      axios.get(`${bookmark_api}?uid=${currentUser.uid}`).then((res) => {
        const list = res.data.bookmarks;
        if (list.length !== null) {
          let dublicate = list.some((person) => {
            return person.document_id === data.document_id;
          });
          if (dublicate) {
            setchecksave(true);
          } else {
            setchecksave(false);
          }
        }
      });
    }
  };

  const handleOpen = (id) => {
    setOpen(true);
    const query = data.filter((d) => d.document_id === id);
    setinternshipIndex(query[0]);
    if (query !== null) checkSave(query[0]);
  };

  const isFavorite = (data) => {
    if (data !== null) {
      // condtion to check if "internship bookmark" already present or else create a empty array
      axios
        .get(`${bookmark_api}?uid=${currentUser.uid}`)
        .then((res) => {
          const internshipBookmarkArray = res.data.bookmarks;
          let dublicate = internshipBookmarkArray.some(
            (person) => person.id === data.document_id
          );
          if (!dublicate) {
            axios
              .post(bookmark_api, {
                uid: currentUser.uid,
                email: currentUser.email,
                document_ids: [data.document_id],
              })
              .then((res) => {
                if (res.status === 200) {
                  enqueueSnackbar("Bookmarked successfully", {
                    variant: "success",
                  });
                  handleClose();
                }
              })
              .catch((err) => {
                enqueueSnackbar("Unable to bookmark.", {
                  variant: "error",
                });
              });
          } else {
            enqueueSnackbar("This data already exist.", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Error while feteching bookmarks.", {
            variant: "error",
          });
        });
    }
  };

  const removeSave = (data) => {
    axios
      .get(`${bookmark_api}?uid=${currentUser.uid}`)
      .then((res) => {
        const items = res.data.bookmarks;
        for (let i = 0; i < items.length; i++) {
          if (items[i]["id"] === data.id) {
            axios
              .delete(bookmark_api, {
                data: {
                  uid: currentUser.uid,
                  email: currentUser.email,
                  document_ids: [data.document_id],
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  // handleClickError();
                  enqueueSnackbar("Bookmark Deleted successfully", {
                    variant: "success",
                  });
                  handleClose();
                }
              })
              .catch((err) => {
                enqueueSnackbar("Unable to delete bookmark.", {
                  variant: "error",
                });
              });
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error while feteching bookmarks.", {
          variant: "error",
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
 
  const handleLoadMore = async () => {
    // increment the pageNo and request for next page data and append the data
    console.log("freshPage ::", page)
    setpageNo(pageNo+1)
    console.log("Page ::", pageNo);
    console.log("Links ::", `${link}?limit=10&page=${pageNo}`);
    await fetch(`${link}?limit=10&page=${pageNo}`)
      .then((response) => response.json())
      .then((res) => {
       
        const apidata = res.data;
        calculate_days_hours(apidata);
        sortbydatetime(apidata);
        setData([...data, ...apidata]);
        setentireData([...entiredata, ...apidata]);
        sortlocation(entiredata);
        setisLoaded(true);
      })
      .catch((err) => {
        seterror(true);
        enqueueSnackbar("Error while loading interns data.", {
          variant: "error",
        });
      });
  };

  // console.log("Jobs data ::", data, "Entire data ::", entiredata);

  return (
    <Container className={classes.container}>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="demo-controlled-open-select-label"
          style={{ marginLeft: 15, fontSize: 13 }}
        >
          All Cities
        </InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          input={<Input />}
          value={location}
          style={{
            marginLeft: 15,
            maxWidth: 110,
            marginBottom: 5,
            fontSize: 12,
          }}
          onChange={getfilteredData}
        >
          <MenuItem value="All Cities">
            <span>
              All Cities
              <span style={{ color: "rgb(133, 140, 148)" }}>
                ({entiredata.length})
              </span>
            </span>
          </MenuItem>
          {listoflocation.map((c, index) => (
            <MenuItem key={index} value={c}>
              <span>
                {c}
                <span style={{ color: "rgb(133, 140, 148)" }}>
                  ({entiredata.filter((d) => d.company_location === c).length})
                </span>
              </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="demo-controlled-open-select-label"
          style={{ marginLeft: 15, fontSize: 13 }}
        >
          Posted Any Time
        </InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          input={<Input />}
          value={date}
          style={{
            marginLeft: 15,
            maxWidth: 125,
            minWidth: 120,
            marginBottom: 5,
            fontSize: 12,
          }}
          onChange={getfiltereddateData}
        >
          <MenuItem value="Posted Any Time">
            <span>
              Posted Any Time
              <span style={{ color: "rgb(133, 140, 148)" }}>
                ({entiredata.length})
              </span>
            </span>
          </MenuItem>

          {datefilter.map((c, index) => (
            <MenuItem key={index} value={c}>
              {entiredata !== undefined || entiredata.length !== 0 ? (
                <span>
                  {c}
                  {c === "Today" && (
                    <span style={{ color: "rgb(133, 140, 148)" }}>
                      (
                      {
                        entiredata.filter(
                          (d) =>
                            d.date_published.toString().includes("hours") ||
                            d.date_published.toString().includes("hour")
                        ).length
                      }
                      )
                    </span>
                  )}
                  <span>
                    {c === "Last Day" && (
                      <span style={{ color: "rgb(133, 140, 148)" }}>
                        (
                        {
                          entiredata.filter(
                            (d) =>
                              (d.date_published.toString().includes("days") &&
                                d.date_published.toString().split(" ")[0] ===
                                  1) ||
                              d.date_published.toString().includes("hours")
                          ).length
                        }
                        )
                      </span>
                    )}
                  </span>
                  <span>
                    {c === "Last 3 Days" && (
                      <span style={{ color: "rgb(133, 140, 148)" }}>
                        (
                        {
                          entiredata.filter(
                            (d) =>
                              (d.date_published.toString().includes("days") &&
                                d.date_published.toString().split(" ")[0] <=
                                  3) ||
                              d.date_published.toString().includes("hours")
                          ).length
                        }
                        )
                      </span>
                    )}
                  </span>
                  <span>
                    {c === "Last Week" && (
                      <span style={{ color: "rgb(133, 140, 148)" }}>
                        (
                        {
                          entiredata.filter(
                            (d) =>
                              (d.date_published.toString().includes("days") &&
                                d.date_published.toString().split(" ")[0] <=
                                  7) ||
                              d.date_published.toString().includes("hours")
                          ).length
                        }
                        )
                      </span>
                    )}
                  </span>
                  <span>
                    {c === "Last 2 Weeks" && (
                      <span style={{ color: "rgb(133, 140, 148)" }}>
                        (
                        {
                          entiredata.filter(
                            (d) =>
                              (d.date_published.toString().includes("days") &&
                                d.date_published.toString().split(" ")[0] <=
                                  14) ||
                              d.date_published.toString().includes("hours")
                          ).length
                        }
                        )
                      </span>
                    )}
                  </span>
                  <span>
                    {c === "Last 4 Weeks" && (
                      <span style={{ color: "rgb(133, 140, 148)" }}>
                        (
                        {
                          entiredata.filter(
                            (d) =>
                              (d.date_published.toString().includes("days") &&
                                d.date_published.toString().split(" ")[0] <=
                                  30) ||
                              d.date_published.toString().includes("hours")
                          ).length
                        }
                        )
                      </span>
                    )}
                  </span>
                </span>
              ) : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error ? (
        <Box display="flex" justifyContent="center" marginTop="30%">
          Unable to load interns data!
        </Box>
      ) : (
        <div>
          {!isLoaded ? (
            <EventsLoader />
          ) : (
            <div>
              <Grid
                container
                className={classes.root}
                spacing={0}
                style={{ oveflow: "auto", position: "relative" }}
              >
                {data.length === 0 || data === null ? (
                  <p style={{ marginLeft: "auto", marginRight: "auto" }}>
                    Result Not Found
                  </p>
                ) : (
                  data.map((c, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card className={classes.root}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={4} md={4}>
                              <Avatar
                                alt={c.company_name}
                                src={c.company_logo}
                                className={classes.media}
                                variant="square"
                                style={{
                                  width: "fit-content",
                                  height: "fit-content",
                                  marginTop: 20,
                                  backgroundColor: "black",
                                }}
                              />
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <Typography
                                variant="body2"
                                color="inherit"
                                style={{
                                  fontSize: 14,
                                  marginTop: 18,
                                }}
                              >
                                {c.company_name}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="inherit"
                                component="h5"
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {c.title}
                              </Typography>
                              <Typography
                                variant="overline"
                                display="block"
                                color="inherit"
                              >
                                {c.company_location}
                              </Typography>
                              <Typography variant="subtitle2" color="inherit">
                                {c.date_published}
                              </Typography>
                              <div
                                style={{
                                  paddingTop: 10,
                                }}
                              >
                                <Button
                                  style={{}}
                                  variant="outlined"
                                  size="small"
                                  onClick={() => handleOpen(c.document_id)}
                                >
                                  Read More
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                )}
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={handleLoadMore}
                >
                  Load more..
                </Button>
              </Grid>
            </div>
          )}
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <h2 id="simple-modal-title">{internshipIndex.name}</h2>
          <Avatar
            alt={internshipIndex.company_name}
            src={internshipIndex.company_logo}
            className={classes.media}
            variant="square"
            style={{
              width: "fit-content",
              height: "fit-content",
              marginTop: 20,
              backgroundColor: "black",
            }}
          />

          <Grid item xs={12} md={8}>
            <Typography
              variant="body2"
              color="inherit"
              style={{
                fontSize: 18,
                marginTop: 25,
              }}
            >
              {internshipIndex.company_name}
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              component="h2"
              style={{
                fontWeight: "bold",
              }}
            >
              {internshipIndex.title}
            </Typography>
            <Typography variant="overline" display="block" color="inherit">
              {internshipIndex.company_location}
            </Typography>
            <Typography variant="subtitle2" color="inherit">
              {internshipIndex.date_published}
            </Typography>
            <Typography variant="subtitle2" color="inherit">
              Intern Responsibilities:
              <ul>
                {internshipIndex.job_description == null ? null : (
                  <li> {internshipIndex.job_description} </li>
                )}
              </ul>
            </Typography>
            <div
              style={{
                textAlign: "center",
                display: "flex",
              }}
            >
              <Button
                onClick={() => window.open(internshipIndex.post_url, "_blank")}
                variant="outlined"
                color="primary"
                size="small"
              >
                Apply now
              </Button>

              {checksave ? (
                <>
                  <Button
                    style={{
                      marginLeft: 15,
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => removeSave(internshipIndex)}
                  >
                    <DeleteIcon />
                    unsave
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    style={{
                      marginLeft: 15,
                    }}
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => isFavorite(internshipIndex)}
                  >
                    <BookmarkBorderIcon />
                    Save
                  </Button>
                </>
              )}
            </div>
          </Grid>
        </div>
      </Modal>
    </Container>
  );
};
