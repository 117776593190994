import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 30,
    paddingLeft: 30,
    background: "white",
    paddingBottom: 30,
  },
  header: {
    fontWeight: "bold",
    paddingLeft: "20px",
  },
  image: {
    minHeight: "250px",
    padding: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
  },
  title: {
    fontWeight: "900",
    paddingLeft: 20,
  },
  buttonParent: {
    paddingTop: 20,
    paddingLeft: 30,
  },
  button: {
    borderRadius: 25,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    color: "white",
    backgroundColor: "black",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  authorName: {
    paddingLeft: 10,
    paddingTop: 0,
  },
  avatardiv: {
    paddingTop: 10,
    paddingLeft: 30,
    display: "flex",
  },
}));

export const FeaturedWorkshop = () => {
  const classes = useStyles();
  function onclickHandler() {
    document.location = window.location.origin + "/blog";
  }

  return (
    <Container maxWidth={false}>
      <Typography
        variant="h6"
        color="inherit"
        gutterBottom
        className={classes.header}
      >
        Featured Workshop
      </Typography>
      <img
        src="\images\featuredimage.png"
        alt="cur"
        className={classes.image}
      />
      <Typography
        variant="h4"
        component="p"
        align="left"
        className={classes.title}
        onClick={onclickHandler}
      >
        React Workshop
      </Typography>
      <div className={classes.avatardiv}>
        <Avatar className={classes.avatar} src="/images/mz.jpg" />

        <Typography component="p" align="left" className={classes.authorName}>
          Mark zuckerberg{" "}
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            on : 25/02/2021
          </span>
        </Typography>
      </div>
      <div className={classes.buttonParent}>
        <Button
          onClick={() =>
            window.open(
              "https://www.meetup.com/Byte_Academy/events/274582266/",
              "_blank"
            )
          }
          className={classes.button}
          variant="outlined"
          color="inherit"
          size="small"
        >
          Register
        </Button>
      </div>
    </Container>
  );
};
