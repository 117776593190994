import React, { createContext, useState, useEffect } from "react";
import { FirebaseAuth } from "../instances/firebaseAuth";
export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [currentUser, setcurrentUser] = useState(null);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const unsubcribe = FirebaseAuth().onAuthStateChanged((user) => {
      setcurrentUser(user);
      setloading(false);
    });
    return unsubcribe;
  }, []);

  const value = {
    currentUser,
    setcurrentUser,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
