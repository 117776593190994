import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Container } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Paper from "@material-ui/core/Paper";
import { DiscordLoader } from "../context/DiscordLoader";
import { config } from "../config/constants";
import { useSnackbar } from "notistack";

let link = config.url.API_URL + config.url.DISCRD_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    position: "relative",
    maxHeight: 530,
  },
  container: {
    "&.MuiContainer-root": {
      paddingLeft: "8px",
      paddingRight: "16px",
      marginBottom: "100px",
    },
  },
}));

export const DiscordComponent = () => {
  const classes = useStyles();
  const [channelName, setChannel] = useState("");
  const [msgs, setMsgs] = useState([]);
  const [dataa, setdata] = React.useState([]);
  const [isLoaded, setisLoaded] = useState(false);
  const [error, seterror] = useState(false);
  const [discordData, setdiscordData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const discordURL = link;

  let c;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(discordURL);
        if (response.status === 200) {
          const data = await response.json();
          setMsgs(data[0]["messages"]);
          setChannel(data[0]["channel"]);
          setdata(data);
          setisLoaded(true);
        } else {
          seterror(true);
          enqueueSnackbar("Error while loading discord data.", {
            variant: "error",
          });
        }
      } catch (err) {
        enqueueSnackbar("Error while loading discord data.", {
          variant: "error",
        });
        seterror(true);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      async function fetchData1() {
        try {
          const response1 = await fetch(discordURL);
          const res = await response1.json();
          setdiscordData(res);
          if (channelName !== null && channelName !== "") {
            discordData
              .filter((d) => d["channel"] === channelName)
              .map((filteredData) => {
                const messages = filteredData["messages"];
                setMsgs(messages);
                return null;
              });
          }
        } catch (err) {
          enqueueSnackbar("Error while loading discord data.", {
            variant: "error",
          });
          seterror(true);
        }
      }
      fetchData1();
    }, 5000);
    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line
  }, [channelName]);

  const getData = (event) => {
    c = event.target.value;
    dataa
      .filter((d) => d["channel"] === c)
      .map((filteredData) => {
        const messages = filteredData["messages"];
        setMsgs(messages);
        setChannel(c);
        return c;
      });
  };

  return (
    <React.Fragment>
      {error ? (
        <Box display="flex" justifyContent="center" marginTop="20%">
          Unable to load discord data.
        </Box>
      ) : (
        <div>
          {!isLoaded ? (
            <DiscordLoader />
          ) : (
            <Container className={classes.container}>
              <Box
                justifyContent="center"
                alignItems="center"
                boxShadow={2}
                marginTop={6}
              >
                <Paper>
                  <div
                    style={{
                      padding: 8,
                      display: "flex",
                      alignItems: "center",
                      overflow: "auto",
                    }}
                  >
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={channelName}
                      onChange={getData}
                      style={{ marginLeft: 50, minWidth: 120 }}
                    >
                      {dataa.map((c, index) => (
                        <MenuItem key={index} value={c.channel}>
                          {c.channel}
                        </MenuItem>
                      ))}
                    </Select>
                    <NotificationsIcon style={{ marginLeft: 20 }} />
                    <PeopleAltIcon style={{ marginLeft: 20 }} />
                  </div>
                  <Divider variant="fullWidth" component="li" />
                  <List className={classes.root}>
                    {msgs.map((data1) => (
                      <ListItem alignItems="flex-start" key={data1.id}>
                        <ListItemAvatar>
                          <Avatar
                            alt={data1.author.name}
                            src={data1["author"]["avatar"]}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Box>
                              <Typography style={{ color: "#e52b50" }}>
                                {data1["author"]["name"]}
                              </Typography>
                              {data1["type"] === "0" && (
                                <Typography variant="caption">
                                  {data1["timestamp"].substring(0, 10)}
                                </Typography>
                              )}
                            </Box>
                          }
                          secondary={
                            <div>
                              <Box>
                                {data1["type"] === "7" && (
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    style={{
                                      backgroundColor: "#F0F0F0",
                                      padding: 4,
                                      borderRadius: 5,
                                    }}
                                  >
                                    <ArrowForwardIcon
                                      style={{
                                        color: "green",
                                        fontSize: 15,
                                      }}
                                    />
                                    {" " +
                                      data1["author"]["name"] +
                                      " " +
                                      "joined the party" +
                                      " " +
                                      data1["timestamp"].substring(0, 10)}
                                  </Typography>
                                )}
                              </Box>
                              <Box>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  style={{
                                    color: "black",
                                  }}
                                >
                                  {data1["content"]}
                                </Typography>
                              </Box>
                            </div>
                          }
                        />
                      </ListItem>
                    ))}
                    <Divider variant="inset" component="li" />
                  </List>
                </Paper>
              </Box>
            </Container>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
