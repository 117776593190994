import React, { useContext, useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Avatar, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import { config } from "../config/constants";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useSnackbar } from "notistack";
import { EventsLoader } from "../context/Carousel";

let link = config.url.API_URL + config.url.JOB_URL;
let bookmark_api = config.url.API_URL + config.url.BOOKMARK_URL;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    boxShadow: "none",
    margin: theme.spacing(1),
    height: "250px",
    width: "330px",
    marginTop: 0,
  },
  list: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    borderRadius: 25,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    color: "white",
    backgroundColor: "black",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}));

function getModalStyle() {
  return {
    width: "100%",
    maxWidth: "100vw",
    maxHeight: "100%",
    position: "fixed",
    top: "50%",
    left: "0",
    transform: "translate(0, -50%)",
    overflowY: "auto",
  };
}

export const InternCarousel = () => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [internshipIndex, setinternshipIndex] = useState([]);
  const [checksave, setchecksave] = useState(false);
  const [data, setData] = useState([]);
  const [error, seterror] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    fetchinternData();
    // eslint-disable-next-line
  }, []);

  function sortbydatetime(list) {
    list.sort((a, b) => b.date_published - a.date_published);
  }
  async function fetchinternData() {
    await fetch(`${link}?limit=10&page=1`)
      .then((response) => response.json())
      .then((res) => {
        const apidata = res.data;
        calculate_days_hours(apidata);
        sortbydatetime(apidata);
        setisLoaded(true);
      })
      .catch((err) => {
        enqueueSnackbar("Error while feteching interns data.", {
          variant: "error",
        });
        seterror(true);
      });
  }
  function calculate_days_hours(list) {
    list.map((d) => {
      const timestampdatetime = d.date_published;
      const date = new Date(timestampdatetime * 1000);
      const timestamp = Date.now();
      var timezone = moment.tz.guess();
      const currentdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(timestamp);
      const timestampdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(date);
      var date_published = moment(timestampdate,'MM/DD/YYYY HH:mm:ss').tz(timezone)
      var currentdatetime = moment(currentdate,'MM/DD/YYYY HH:mm:ss').tz(timezone)

      var duration = moment.duration(
        moment(currentdatetime).diff(moment(date_published))
      );
      var days = duration.asDays();
      var hours = duration.asHours();
      if (days > 1) {
        d.date_published = `${Math.round(days)} days ago`;
      } else {
        d.date_published = `${Math.round(hours)} hours ago`;
      }
      return null;
    });
    setData(list);
  }

  const checkSave = (data) => {
    if (data !== null) {
      // get all the bookmark interns/jobs and check for dulicate
      axios.get(`${bookmark_api}?uid=${currentUser.uid}`).then((res) => {
        const list = res.data.bookmarks;
        if (list.length !== null) {
          let duplicate = list.some((person) => {
            return person.document_id === data.document_id;
          });
          if (duplicate) {
            setchecksave(true);
          } else {
            setchecksave(false);
          }
        }
      });
    }
  };

  const handleOpen = (id) => {
    setOpen(true);
    const query = data.filter((d) => d.document_id === id);
    setinternshipIndex(query[0]);
    if (query != null) checkSave(query[0]);
  };

  const isFavorite = (data) => {
    if (data != null) {
      // condtion to check if "internship bookmark" already present or else create a empty array
      axios
        .get(`${bookmark_api}?uid=${currentUser.uid}`)
        .then((res) => {
          const internshipBookmarkArray = res.data.bookmarks;
          let duplicate = internshipBookmarkArray.some(
            (person) => person.id === data.document_id
          );
          if (!duplicate) {
            axios
              .post(bookmark_api, {
                uid: currentUser.uid,
                email: currentUser.email,
                document_ids: [data.document_id],
              })
              .then((res) => {
                if (res.status === 200) {
                  enqueueSnackbar("Bookmarked successfully.", {
                    variant: "success",
                  });
                  handleClose();
                }
              })
              .catch((err) => {
                enqueueSnackbar("Unable to bookmark.", {
                  variant: "error",
                });
              });
          } else {
            enqueueSnackbar("This data already exist.", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Error while feteching bookmarks.", {
            variant: "error",
          });
        });
    }
  };

  const removeSave = (data) => {
    axios
      .get(`${bookmark_api}?uid=${currentUser.uid}`)
      .then((res) => {
        const items = res.data.bookmarks;
        for (let i = 0; i < items.length; i++) {
          if (items[i]["id"] === data.id) {
            axios
              .delete(bookmark_api, {
                data: {
                  uid: currentUser.uid,
                  email: currentUser.email,
                  document_ids: [data.document_id],
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  enqueueSnackbar("Bookmark Deleted successfully.", {
                    variant: "success",
                  });
                  handleClose();
                }
              })
              .catch((err) => {
                enqueueSnackbar("Unable to delete bookmark.", {
                  variant: "error",
                });
              });
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error while feteching bookmarks.", {
          variant: "error",
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Container maxWidth={false}>
        <Box marginTop={2} marginBottom={2}>
          <div
            style={{
              paddingLeft: 20,
              paddingTop: 20,
            }}
          >
            <Typography
              variant="h6"
              color="inherit"
              gutterBottom
              style={{
                fontWeight: "bold",
              }}
            >
              <Link underline="none" color="inherit" href="/interns">
                Internships
              </Link>
            </Typography>
          </div>
          <div>
            {error ? (
              <Box display="flex" justifyContent="center">
                Unable to load intern data.
              </Box>
            ) : (
              <div>
                {!isLoaded ? (
                  <EventsLoader />
                ) : (
                  <Carousel
                    swipeable={true}
                    responsive={responsive}
                    removeArrowOnDeviceType={["mobile"]}
                    itemClass={classes.list}
                  >
                    {data.slice(0, 5).map((c, index) => (
                      <Card className={classes.root} key={index}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={4} md={4}>
                              <Avatar
                                alt={c.company_name}
                                src={c.company_logo}
                                variant="square"
                                style={{
                                  width: "fit-content",
                                  height: "fit-content",
                                  marginTop: 30,
                                  backgroundColor: "black",
                                }}
                              />
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <Typography
                                variant="body1"
                                color="inherit"
                                style={{
                                  marginTop: 25,
                                }}
                              >
                                {c.company_name.substring(0, 13)}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="inherit"
                                component="h2"
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {c.title.substring(0, 15)}
                              </Typography>
                              <Typography
                                variant="overline"
                                display="block"
                                color="inherit"
                              >
                                {c.company_location}
                              </Typography>
                              <Typography variant="subtitle2" color="inherit">
                                {c.date_published}
                              </Typography>
                              <div
                                style={{
                                  paddingTop: 10,
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="inherit"
                                  // className={classes.button}
                                  onClick={() => handleOpen(c.document_id)}
                                >
                                  Read More
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ))}
                    <Button
                      onClick={() => window.open("/interns", "_self")}
                      className={classes.button}
                      variant="outlined"
                      color="primary"
                      size="small"
                    >
                      See all
                    </Button>
                  </Carousel>
                )}
              </div>
            )}
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle} className={classes.paper}>
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />{" "}
              </IconButton>
              <h2 id="simple-modal-title">{internshipIndex.name}</h2>
              <Avatar
                alt={internshipIndex.company_name}
                src={internshipIndex.company_logo}
                className={classes.media}
                variant="square"
                style={{
                  width: "150px",
                  height: "60px",
                  marginTop: 18,
                  backgroundColor: "black",
                }}
              />

              <Grid item xs={8} md={8}>
                <Typography
                  variant="body2"
                  color="inherit"
                  style={{
                    fontSize: 18,
                    marginTop: 25,
                  }}
                >
                  {internshipIndex.company_name}
                </Typography>
                <Typography
                  variant="body1"
                  color="inherit"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {internshipIndex.title}
                </Typography>
                <Typography variant="overline" display="block" color="inherit">
                  {internshipIndex.company_location}
                </Typography>
                <Typography variant="subtitle2" color="inherit">
                  {internshipIndex.date_published}
                </Typography>
                <Typography variant="subtitle2" color="inherit">
                  Intern Responsibilities:
                  <ul>
                    {internshipIndex.job_description == null ? null : (
                      <li> {internshipIndex.job_description} </li>
                    )}
                  </ul>
                </Typography>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    onClick={() =>
                      window.open(internshipIndex.post_url, "_blank")
                    }
                    variant="outlined"
                    color="inherit"
                    size="small"
                  >
                    Apply now
                  </Button>

                  {checksave ? (
                    <>
                      <Button
                        style={{
                          marginLeft: 15,
                        }}
                        variant="outlined"
                        color="inherit"
                        size="small"
                        onClick={() => removeSave(internshipIndex)}
                      >
                        <DeleteIcon />
                        unsave
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        style={{
                          marginLeft: 15,
                        }}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        onClick={() => isFavorite(internshipIndex)}
                      >
                        <BookmarkBorderIcon />
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </Grid>
            </div>
          </Modal>
        </Box>
      </Container>
    </React.Fragment>
  );
};
