import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Box, Container, Paper } from "@material-ui/core";
import moment from "moment";
import { FeedLoader } from "../context/FeedLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    position: "relative",
    maxHeight: 550,
  },
  inline: {
    display: "inline",
  },
  primaryText: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(0.5),
      textDecoration: "none",
    },
  },
  secondaryText: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  listItem: {
    "&.MuiListItem-root": {
      padding: 0,
    },
  },
  paper: {
    padding: theme.spacing(2),
    width: "100%",
  },
  container: {
    "&.MuiContainer-root": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  postBtn: {
    "&.MuiButton-outlinedPrimary": {
      borderRadius: "20px",
      width: "50%",
      color: "black",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid grey",
    },
  },
}));

export const FeedComponent = () => {
  const classes = useStyles();
  const [feeds, setfeeds] = useState([]);
  const [isLoaded, setisLoaded] = useState(false);

  const feedURL = "https://www.techspot.com/community/forums/-/index.rss";

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        "https://api.rss2json.com/v1/api.json?rss_url=" + feedURL
      );
      const data = await response.json();
      const items = data.items;
      setfeeds(items);
      setisLoaded(true);
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      {!isLoaded ? (
        <FeedLoader />
      ) : (
        <Container fixed className={classes.container}>
          <Box marginTop={2} boxShadow={2}>
            <Paper className={classes.paper}>
              <List className={classes.root}>
                {feeds.slice(1, 10).map((feed, index) => (
                  <ListItem
                    alignItems="flex-start"
                    key={index}
                    className={classes.listItem}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={feed.author.slice(21)}
                        src="/static/images/avatar/1.jpg"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <div className={classes.primaryText}>
                          <Typography component="a" href="#">
                            {feed.author.slice(20)}
                          </Typography>
                          <Typography>{feed.categories[0]}</Typography>
                          <Typography variant="caption">
                            {moment(feed.pubDate).format("MMMM Do YYYY")}
                          </Typography>
                        </div>
                      }
                      secondary={
                        <div className={classes.secondaryText}>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {feed.content !== null && feed.content !== ""
                              ? feed.content.replace(/<[^>]+>/g, "")
                              : null}
                          </Typography>
                        </div>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Box>
        </Container>
      )}
    </React.Fragment>
  );
};
