import "./App.css";
import { createContext, createRef } from "react";
import { MainForm } from "./components/signUpForm/MainForm";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import RalewayWoff2 from "./fonts/raleway-v18-latin/raleway-v18-latin-regular.woff2";
import { CssBaseline } from "@material-ui/core";
import { MemberFeed } from "./components/memberFeed/MemberFeed";
import { SignInForm } from "./components/signIn/SignInForm";
import { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PrivateRoute } from "./router/PrivateRoute";
import { AuthProvider } from "./components/context/AuthContext";
import { Checkout } from "./components/payment/Checkout";
import { ThankYou } from "./components/payment/thankyou";
import Footer from "./components/memberFeed/Footer";
import { Login } from "./components/context/Login";
import { SnackbarProvider } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const raleway = {
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Raleway'),
    local('Raleway-Regular'),
    url(${RalewayWoff2}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createMuiTheme({
  typography: {
    fontFamily: "Raleway, Arial",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [raleway],
      },
    },
  },
});

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_4Spq514S6WZpbxWY0n2uts0q");

export const EmailContext = createContext("");

function App() {
  const [value, setvalue] = useState("");
  const notistackRef = createRef();

  const onClickDismiss = (key) => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <CssBaseline />
          <AuthProvider>
            <Router>
              <Switch>
                <SnackbarProvider
                  maxSnack={5}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  preventDuplicate
                  ref={notistackRef}
                  action={(key) => (
                    <IconButton
                      onClick={() => {
                        onClickDismiss(key);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                >
                  <EmailContext.Provider value={{ value, setvalue }}>
                    <Route exact path="/signin" component={SignInForm} />
                    <Route exact path="/signup" component={MainForm} />
                    <PrivateRoute exact path="/home" component={MemberFeed} />
                    <PrivateRoute path="/blog" component={MemberFeed} />
                    <PrivateRoute path="/discord" component={MemberFeed} />
                    <PrivateRoute path="/feed" component={MemberFeed} />
                    <PrivateRoute
                      exact
                      path="/interns"
                      component={MemberFeed}
                    />
                    <PrivateRoute
                      exact
                      path="/workshops"
                      component={MemberFeed}
                    />
                    <PrivateRoute
                      exact
                      path="/bookmark"
                      component={MemberFeed}
                    />
                    <PrivateRoute
                      exact
                      path="/addworkshop"
                      component={MemberFeed}
                    />
                    <Route exact path="/" component={Login} />
                    <Route exact path="/footer" component={Footer} />
                    <PrivateRoute exact path="/checkout" component={Checkout} />
                    <PrivateRoute exact path="/thankyou" component={ThankYou} />
                  </EmailContext.Provider>
                </SnackbarProvider>
              </Switch>
            </Router>
          </AuthProvider>
        </Elements>
      </ThemeProvider>
    </>
  );
}
export default App;
