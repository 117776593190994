import { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Box, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0.5),
      width: "25ch",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    // top: theme.spacing.unit / 2,
    color: theme.palette.grey[500],
  },
}));

export const AddnewWorkshop = () => {
  const [data, setData] = useState({
    name: "",
    url: "",
    date: moment(),
    content: "",
    location: "",
    duration: "",
    fee: "",
    presenter: "",
  });
  const [helperText, sethelperText] = useState("");
  const [error, seterror] = useState(false);
  const [helperTextDuration, sethelperTextDuration] = useState("");
  const [errorduration, seterrorduration] = useState(false);

  const classes = useStyles();

  const handleDateChange = (event) => {
    setData({
      date: event.target.value,
    });
  };

  const onChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setData({ fee: event.target.value });
      sethelperText("");
      seterror(false);
    } else {
      sethelperText("Invalid format");
      seterror(true);
    }
  };

  const onChangeDuration = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setData({ duration: event.target.value });
      sethelperTextDuration("");
      seterrorduration(false);
    } else {
      sethelperTextDuration("Invalid format");
      seterrorduration(true);
    }
  };

  console.log(data);

  const handleSubmit = () => {
    console.log(data);
  };

  return (
    <>
      <Container className={classes.container}>
        <Box marginTop={2} marginBottom={2}>
          <div
            style={{
              paddingLeft: 20,
              paddingBottom: 20,
              paddingTop: 20,
            }}
          >
            <Typography
              variant="h6"
              color="inherit"
              gutterBottom
              style={{
                fontWeight: "bold",
              }}
            >
              <Link underline="none" color="inherit" href="/blog">
                Add new workshop
              </Link>
            </Typography>
          </div>
          <Card className={classes.root}>
            <CardContent>
              <form
                onSubmit={handleSubmit}
                className={classes.root}
                noValidate
                autoComplete="off"
              >
                <TextField
                  required
                  onInput={(e) => {
                    setData({ name: e.target.value });
                  }}
                  label="Name"
                  fullWidth
                />
                <TextField
                  required
                  onInput={(e) => {
                    setData({ url: e.target.value });
                  }}
                  label="Url"
                  fullWidth
                />
                {data.url ? (
                  <>
                    <div
                      style={{
                        paddingTop: 20,
                      }}
                    >
                      <img height="100" width="100" src={data.url} alt="" />
                    </div>
                  </>
                ) : (
                  ""
                )}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TextField
                    required
                    id="datetime-local"
                    label="Date"
                    type="datetime-local"
                    defaultValue="2017-05-24T10:30"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>

                <TextField
                  required
                  onInput={(e) => {
                    setData({ content: e.target.value });
                  }}
                  label="Content"
                  fullWidth
                />

                <TextField
                  required
                  onInput={(e) => {
                    setData({ location: e.target.value });
                  }}
                  label="Location"
                  fullWidth
                />

                <TextField
                  helperText={helperTextDuration}
                  onInput={onChangeDuration.bind(this)}
                  error={errorduration}
                  required
                  id="outlined-required"
                  label="Duration"
                />

                <TextField
                  helperText={helperText}
                  onInput={onChange.bind(this)}
                  error={error}
                  required
                  id="outlined-required"
                  label="Fee"
                />

                <TextField
                  required
                  onInput={(e) => {
                    setData({ presenter: e.target.value });
                  }}
                  label="Presenter Name"
                  fullWidth
                />

                <Typography className={classes.divider} />
                <Button type="submit" className={classes.button}>
                  Submit
                </Button>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
};
