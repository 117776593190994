import React, { useContext, useState } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "../components/context/AuthContext";
import { Typography, DialogContent, Dialog, Link } from "@material-ui/core";
import { FirebaseAuth, Provider } from "../components/instances/firebaseAuth";
import { config } from "../components/config/constants";
import axios from "axios";
import { useSnackbar } from "notistack";

let link = config.url.API_URL + config.url.CREATE_USER_URL;

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      component={(props) => {
        return currentUser || config.url.DEVELOPMENT_ENVIRONMENT ? (
          <Component {...props} />
        ) : (
          <LoginModal /> // this is will route to Login Modal pop up
        );
      }}
    />
  );
};

const LoginModal = () => {
  const [open, setopen] = useState(true);
  const [signup, setsignup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setopen(false);
    window.open("/", "_self");
  };

  const signUpButton = () => {
    setsignup(!signup);
  };

  const handleSignIn = () => {
    FirebaseAuth()
      .signInWithPopup(Provider)
      .then((user) => {
        const userJson = {
          email_address: user.additionalUserInfo.profile.email,
          display_name: user.additionalUserInfo.profile.name,
          photo_url: user.additionalUserInfo.profile.picture,
          uid: user.user.uid,
          tokens: {
            access_token: user.credential.accessToken,
            refresh_token: user.user.refreshToken,
          },
          provider: user.credential.providerId,
        };
        // if it is a new user, send the user credential to backend to create new user
        // show the signup page for membership
        axios.post(link, userJson).then(function (response) {
          if (response.status === 200) {
            enqueueSnackbar("Account created succesfull.", {
              variant: "success",
            });
            const isNewUser = response.data.is_new_user;
            if (isNewUser) {
              window.open("/signup", "_self");
            } else {
              const path =
                window.location.pathname === "/"
                  ? "/home"
                  : window.location.pathname;
              window.open(path, "_self");
            }
          }
        });
      })
      .catch(function (error) {
        enqueueSnackbar("Error while creating a user.", {
          variant: "error",
        });
      })
      .catch((err) => {
        enqueueSnackbar("Error while creating a user.", {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      {signup ? (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="h2"
              style={{
                textAlign: "center",
                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              Join Nestria
            </Typography>
            <img
              src="\images\nestrialogo.png"
              alt="nestrialogo"
              height="140"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                padding: 20,
              }}
            />
            <img
              style={{
                objectFit: "contain",
                display: "block",
                marginLeft: "auto",
                width: "200px",
                marginRight: "auto",
              }}
              src="\images\GoogleSignUpDark-removebg-preview.png"
              onClick={() => handleSignIn()}
              alt="logo"
            />
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{
                textAlign: "center",
                padding: 20,
              }}
            >
              Already have account? &nbsp;
              <Link
                component="button"
                variant="body2"
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textDecoration: "none",
                  marginBottom: "4px",
                }}
                onClick={signUpButton}
              >
                Signin
              </Link>
            </Typography>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="h2"
              style={{
                textAlign: "center",
                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              Welcome Back.
            </Typography>
            <img
              src="\images\nestrialogo.png"
              alt="nestrialogo"
              height="140"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                padding: 20,
              }}
            />
            <img
              style={{
                objectFit: "contain",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "200px",
              }}
              src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png"
              onClick={() => handleSignIn()}
              alt="logo"
            />
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{
                textAlign: "center",
                padding: 20,
              }}
            >
              No account? &nbsp;
              <Link
                component="button"
                variant="body2"
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textDecoration: "none",
                  marginBottom: "4px",
                }}
                onClick={signUpButton}
              >
                Create one
              </Link>
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};
