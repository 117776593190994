const prod = {
  url: {
    API_URL: "https://us-central1-nestria-d07e4.cloudfunctions.net",
    BLOG_URL: "/nestria/blogs",
    DISCRD_URL: "/get_top_channel_messages",
    JOB_URL: "/nestria/jobs",
    BOOKMARK_URL: "/nestria/bookmarks",
    CREATE_USER_URL: "/nestria/create-user",
    GET_USER_DATA_URL: "/nestria/get-user",
    DEVELOPMENT_ENVIRONMENT: false,
  },
};
const dev = {
  url: {
    API_URL: "https://us-central1-nestria-d07e4.cloudfunctions.net",
    BLOG_URL: "/nestria/blogs",
    DISCRD_URL: "/get_top_channel_messages",
    JOB_URL: "/nestria/jobs",
    BOOKMARK_URL: "/nestria/bookmarks",
    CREATE_USER_URL: "/nestria/create-user",
    GET_USER_DATA_URL: "/nestria/get-user",
    DEVELOPMENT_ENVIRONMENT: true,
  },
};

export const config = process.env.REACT_APP_URL === "development" ? dev : prod;

// The variables are stored as key:values; you could add other URLs within the same key. We are also exporting a config constant which contains a conditional (ternary) operator which will return the development or production variable based on the value of the process.env.NODE_ENV
