import React from "react";
import { Box } from "@material-ui/core";
// import { multiStepContext } from "../context/StepContext";
import { TextField } from "formik-material-ui";
import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "300px",
  },
}));

export const ContactInfoForm = () => {
  const classes = useStyles();

  return (
    <div>
      <Box paddingBottom={2}>
        <Field
          disabled={false}
          name="linkedin_username"
          label="LinkedIn username"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={2}>
        <Field
          disabled={false}
          name="discord_id"
          label="Discord ID"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={2}>
        <Field
          disabled={false}
          name="github_handle"
          label="Github"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
    </div>
  );
};
