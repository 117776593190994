import React, { useContext } from "react";
import { CardMedia, Button } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import { EmailContext } from "../../App";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "100%",
    marginTop: theme.spacing(2),
    "&.MuiButton-containedPrimary": {
      backgroundColor: "black",
      color: "white",
    },
  },
  cardMedia: {
    width: "70px",
    height: "70px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

const moreSchema = yup.object().shape({
  firstname: yup.string().required("First Name is required").max(20),
  lastname: yup.string().required("Last Name is required").max(20),
  email: yup.string().email("Invalid email").required("Email is required"),
});

export const OtherForm = () => {
  const classes = useStyles();
  const { value } = useContext(EmailContext);

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: value,
          phone: "",
        }}
        validationSchema={moreSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className={classes.form}>
            <CardMedia
              component="img"
              src={"/images/nestrialogo.png"}
              title="Nestria"
              className={classes.cardMedia}
            />
            <Field
              name="firstname"
              label="First Name"
              margin="dense"
              variant="outlined"
              component={TextField}
              className={classes.field}
            />
            <Field
              name="lastname"
              label="Last Name"
              margin="dense"
              variant="outlined"
              component={TextField}
              className={classes.field}
            />
            <Field
              component={TextField}
              name="email"
              type="email"
              label="Email"
              variant="outlined"
              margin="dense"
              className={classes.field}
            />
            <Field
              type="number"
              name="phone"
              label="Phone Number"
              margin="dense"
              variant="outlined"
              component={TextField}
              className={classes.field}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              size="medium"
              className={classes.field}
            >
              Sign up
            </Button>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
