import React, { useState, useEffect, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { FirebaseAuth, Provider } from "../instances/firebaseAuth";
import { config } from "../config/constants";
import { Container, Box } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Redirect } from "react-router-dom";

let link = config.url.API_URL + config.url.CREATE_USER_URL;

export const Login = () => {
  const [signup, setsignup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useContext(AuthContext);
  const [logIn, setlogIn] = useState(false);

  useEffect(() => {
    currentUser ? setlogIn(true) : setlogIn(false);
    // eslint-disable-next-line
  }, []);

  const signUpButton = () => {
    setsignup(!signup);
  };

  const handleSignIn = () => {
    FirebaseAuth()
      .signInWithPopup(Provider)
      .then((user) => {
        const userJson = {
          email_address: user.additionalUserInfo.profile.email,
          display_name: user.additionalUserInfo.profile.name,
          photo_url: user.additionalUserInfo.profile.picture,
          uid: user.user.uid,
          tokens: {
            access_token: user.credential.accessToken,
            refresh_token: user.user.refreshToken,
          },
          provider: user.credential.providerId,
        };
        // if it is a new user, send the user credential to backend to create new user
        // show the signup page for membership
        axios
          .post(link, userJson)
          .then(function (response) {
            if (response.status === 200) {
              enqueueSnackbar("Account created succesfull.", {
                variant: "success",
              });
              const isNewUser = response.data.is_new_user;
              if (isNewUser) {
                window.open("/signup", "_self");
              } else {
                const path =
                  window.location.pathname === "/"
                    ? "/home"
                    : window.location.pathname;
                window.open(path, "_self");
              }
            }
          })
          .catch(function (error) {
            enqueueSnackbar("Error while creating a user.", {
              variant: "error",
            });
          });
      })
      .catch((err) => {
        enqueueSnackbar("Error while signing in or login in.", {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      {logIn ? (
        <Redirect to="/home" />
      ) : (
        <>
          {signup ? (
            <>
              <Container>
                <Box marginTop={20}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="h2"
                    style={{
                      textAlign: "center",
                      fontSize: 24,
                      fontWeight: "bold",
                    }}
                  >
                    Join Nestria
                  </Typography>
                  <img
                    src="\images\nestrialogo.png"
                    alt="nestrialogo"
                    height="140"
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      padding: 20,
                    }}
                  />
                  <img
                    style={{
                      objectFit: "contain",
                      display: "block",
                      marginLeft: "auto",
                      width: "100%",
                      marginRight: "auto",
                      height: "40px",
                    }}
                    src="\images\GoogleSignUpDark-removebg-preview.png"
                    onClick={handleSignIn}
                    alt="logo"
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{
                      textAlign: "center",
                      padding: 20,
                    }}
                  >
                    Already have account? &nbsp;
                    <Link
                      component="button"
                      variant="body2"
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                      onClick={signUpButton}
                    >
                      Signin
                    </Link>
                  </Typography>
                </Box>
              </Container>
            </>
          ) : (
            <>
              <Container>
                <Box marginTop={20}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="h2"
                    style={{
                      textAlign: "center",
                      fontSize: 24,
                      fontWeight: "bold",
                    }}
                  >
                    Welcome Back.
                  </Typography>
                  <img
                    src="\images\nestrialogo.png"
                    alt="nestrialogo"
                    height="140"
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      padding: 20,
                    }}
                  />
                  <img
                    style={{
                      objectFit: "contain",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      height: "40px",
                    }}
                    src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png"
                    onClick={() => handleSignIn()}
                    alt="logo"
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{
                      textAlign: "center",
                      padding: 20,
                    }}
                  >
                    No account? &nbsp;
                    <Link
                      component="button"
                      variant="body2"
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                      onClick={() => signUpButton()}
                    >
                      Create one
                    </Link>
                  </Typography>
                </Box>
              </Container>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};
