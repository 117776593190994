import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Avatar, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { EventsLoader } from "../context/Carousel";

const data2 = [
  {
    name: "Web Development Workshop",
    image: "https://miro.medium.com/max/700/1*zJkojKNpFD9HFGPJLCs15Q.jpeg",
    content: "Html/Css,Bootstrap,Wordpress",
    date: "1613363400",
    end_session: "1613370600",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Python Workshop",
    image:
      "https://symbols.getvecta.com/stencil_92/75_python-vertical.6c7f1f8721.svg",
    content: "Basic Python",
    date: "1613799000",
    end_session: "1613806200",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "React.js Workshop",
    image:
      "https://seeklogo.com/images/R/react-logo-7B3CE81517-seeklogo.com.png",
    content: "React.js",
    date: "1614223800",
    end_session: "1614231000",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Vue.js Workshop",
    image:
      "https://image.slidesharecdn.com/vuejsnew-180820072551/95/vuejs-workshop-2-638.jpg?cb=1534750615",
    content: "Vue.js",
    date: "1614483000",
    end_session: "1614493800",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Django Workshop",
    image:
      "https://blog.pusher.com/wp-content/uploads/2018/03/the-definitive-django-and-pusher-online-workshop-header.png",
    content: "Django framework",
    date: "1612179000",
    end_session: "1612186200",
    location: "Chicago",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Flask Workshop",
    image:
      "https://storage.googleapis.com/ehimages/2018/10/1/img_1bc4d76b6563bd4688276ccbf5047e95_1538380835324_processed_original.jpg",
    content: "Flask",
    date: "1611135000",
    end_session: "1611138600",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "AWS Workshop",
    image: "https://miro.medium.com/max/1200/1*WwcSmLQHxEt4STfu3bXN7w.png",
    content: "AWS",
    date: "1611120600",
    end_session: "1611132300",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Node.js Workshop",
    image: "https://i.ytimg.com/vi/U8XF6AFGqlc/maxresdefault.jpg",
    content: "Node",
    date: "1611484200",
    end_session: "1611491400",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Nuxt.js Workshop",
    image:
      "https://image.slidesharecdn.com/nuxtjs-161214153928/95/nuxtjs-introduction-1-638.jpg",
    content: "Node",
    date: "1611138600",
    end_session: "1611145800",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Strapi Workshop",
    image:
      "https://cdn-images-1.medium.com/max/1200/1*M79qVYy7vnpSolBHoEulTg.jpeg",
    content: "Strapi",
    date: "1611577800",
    end_session: "1611586800",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Bootstrap Workshop",
    image: "https://www.drupal.org/files/project-images/bootstrap-stack.png",
    content: "Bootstrap",
    date: "1611667800",
    end_session: "1611673200",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
    boxShadow: "none",
    // background: "rgb(250, 250, 250)",
    margin: theme.spacing(1),
    // height: "280px",
    width: "300px",
  },
  media: {
    padding: theme.spacing(0),
    // width: "150px",
    // height: "50px",
  },
  Carousel: {
    padding: theme.spacing(0),
  },
  list: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    borderRadius: 25,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    color: "white",
    backgroundColor: "black",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  link: {
    marginBottom: 70,
    fontSize: 22,
  },
  detail: {
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}));

function getModalStyle() {
  return {
    width: "100%",
    maxWidth: "100vw",
    maxHeight: "100%",
    position: "fixed",
    top: "50%",
    left: "0",
    transform: "translate(0, -50%)",
    overflowY: "auto",
  };
}

export const WorkshopCarousel = () => {
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [workshopIndex, setworkshopIndex] = React.useState([]);
  const [isLive, setisLive] = useState(null);
  const [data, calculatedData] = useState([]);
  const [isLoaded, setisLoaded] = useState(false);
  const classes = useStyles();

  const checklive = (data) => {
    const timestampdatetime = data.date;
    const date = new Date(timestampdatetime * 1000);
    const end_session1 = new Date(data.end_session * 1000);
    const timestamp = Date.now();
    var timezone = moment.tz.guess();
    const currentdate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(timestamp);
    const timestampdate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
    const e_s = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(end_session1);
    const time_live = moment(timestampdate).tz(timezone).format();
    const current_time = moment(currentdate).tz(timezone).format();
    const es = moment(e_s).tz(timezone).format();

    if (moment(current_time).isBetween(time_live, es)) {
      setisLive(true);
    } else {
      setisLive(false);
    }
  };

  useEffect(() => {
    calculate_days_hours();
    setisLoaded(true);
  }, []);

  function calculate_days_hours() {
    const arr = [];
    data2.map((d) => {
      d.date2 = d.date;
      const date = new Date(parseInt(d.date) * 1000);
      const es = new Date(parseInt(d.end_session) * 1000);
      const timestamp = Date.now();
      var timezone = moment.tz.guess();
      const currentdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(timestamp);
      const timestampdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(date);
      const esdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(es);

      var date_published = moment(timestampdate,'MM/DD/YYYY HH:mm:ss').tz(timezone)
      var currentdatetime = moment(currentdate,'MM/DD/YYYY HH:mm:ss').tz(timezone)
      var es_datetime = moment(esdate,'MM/DD/YYYY HH:mm:ss').tz(timezone)
      d.date_published = date_published;
      d.es_datetime = es_datetime;
      var duration = moment.duration(
        moment(es_datetime).diff(moment(currentdatetime))
      );
      var days = duration.asDays();
      var hours = duration.asHours();
      if (new Date(timestampdate).getMinutes() === 0) {
        d.date1 = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          weekday: "long",
        }).format(date);
      } else {
        d.date1 = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          weekday: "long",
        }).format(date);
      }
      if (Math.round(days) > 1) {
        arr.push(d);
      } else if (Math.round(days) === 1) {
        arr.push(d);
      } else if (Math.round(hours) >= 1 && Math.round(hours) <= 24) {
        arr.push(d);
      } else if (Object.is(Math.round(hours), 0)) {
        arr.push(d);
      }
      return null;
    });

    const arr1 = arr.sort((a, b) => a.date2 - b.date2);
    calculatedData(arr1);
  }

  const handleOpen = (name) => {
    setOpen(true);
    const query = data.filter((d) => d.name === name);
    setworkshopIndex(query[0]);
    if (query[0] !== null || query[0] !== []) {
      checklive(query[0]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Container maxWidth={false}>
        <Box marginTop={2} marginBottom={2}>
          <Typography
            variant="h6"
            color="inherit"
            style={{
              marginLeft: 10,
              fontWeight: "bold",
            }}
          >
            <Link underline="none" color="inherit" href="/workshops">
              Upcoming Workshops
            </Link>
          </Typography>
          <div>
            {!isLoaded ? (
              <EventsLoader />
            ) : (
              <Carousel
                swipeable={true}
                responsive={responsive}
                className={classes.Carousel}
                removeArrowOnDeviceType={["mobile"]}
                itemClass={classes.list}
              >
                {data.slice(0, 5).map((c, index) => (
                  <Card className={classes.root} key={index}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={4} md={4}>
                          <Avatar
                            alt={c.name}
                            src={c.image}
                            className={classes.media}
                            variant="square"
                            style={{
                              width: "fit-content",
                              height: "fit-content",
                              marginTop: 6,
                              maxWidth: " 100%",
                              maxHeight: "100%",
                            }}
                          />
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <Typography
                            variant="body1"
                            color="inherit"
                            component="h2"
                            style={{
                              fontWeight: "bold",
                              fontSize: 18,
                            }}
                          >
                            {c.name.substring(0, 13)}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="inherit"
                            className={classes.detail}
                          >
                            {c.Presenter_name}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="inherit"
                            className={classes.detail}
                          >
                            Sessions : {c.duration}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="inherit"
                            className={classes.detail}
                          >
                            Start Session :
                            {moment(c.date_published,'MM/DD/YYYY HH:mm:ss').format("LLL")}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="inherit"
                            className={classes.detail}
                          >
                            End Session :{moment(c.es_datetime,'MM/DD/YYYY HH:mm:ss').format("LLL")}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="inherit"
                            className={classes.detail}
                          >
                            Fee : {c.Fee}
                          </Typography>
                          <Button
                            variant="outlined"
                            color="inherit"
                            size="small"
                            onClick={() => handleOpen(c.name)}
                          >
                            Read More
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
                <Button
                  onClick={() => window.open("/workshops", "_self")}
                  className={classes.button}
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  See all
                </Button>
              </Carousel>
            )}
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle} className={classes.paper}>
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />{" "}
              </IconButton>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Avatar
                    alt={workshopIndex.name}
                    src={workshopIndex.image}
                    className={classes.media}
                    variant="square"
                    style={{
                      width: "fit-content",
                      height: "fit-content",
                      marginTop: 6,
                    }}
                  />
                </Grid>
                <Grid item xs={8} md={8}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    component="h2"
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    {workshopIndex.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    className={classes.detail}
                  >
                    {workshopIndex.Presenter_name}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    className={classes.detail}
                  >
                    Sessions : {workshopIndex.duration}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    className={classes.detail}
                  >
                    Start Session :
                    {moment(workshopIndex.date_published).format("LLL")}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    className={classes.detail}
                  >
                    End Session :
                    {moment(workshopIndex.es_datetime).format("LLL")}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    className={classes.detail}
                  >
                    Fee : {workshopIndex.Fee}
                  </Typography>
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                    }}
                  >
                    <Button
                      onClick={() =>
                        window.open("https://www.byteacademy.co/", "_blank")
                      }
                      variant="outlined"
                      color="inherit"
                      size="small"
                    >
                      Register
                    </Button>
                    {isLive ? (
                      <>
                        <Button
                          style={{
                            marginLeft: 15,
                          }}
                          color="inherit"
                          variant="outlined"
                          size="small"
                          onClick={(e) => e.preventDefault()}
                        >
                          Join now
                        </Button>
                      </>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Modal>
        </Box>
      </Container>
    </React.Fragment>
  );
};
