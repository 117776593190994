import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { Avatar, Box } from "@material-ui/core";
import { config } from "../../config/constants";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import CardActionArea from "@material-ui/core/CardActionArea";

let bookmark_api = config.url.API_URL + config.url.BOOKMARK_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: "none",
    background: "rgb(250, 250, 250)",
    maxHeight: "600px",
    position: "relative",
    overflow: "auto",
    margin: 0,
  },
  container: {
    marginTop: "10px",
  },
  cardMedia: {
    "&.MuiCardMedia-media": {
      backgroundColor: "black",
    },
  },
  bookmarkempty: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  titlediv: {
    paddingLeft: 20,
    paddingTop: 20,
  },
  title: {
    fontWeight: "bold",
  },
  paper: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}));

function getModalStyle() {
  return {
    width: "100%",
    maxWidth: "100vw",
    maxHeight: "100%",
    position: "fixed",
    top: "50%",
    left: "0",
    transform: "translate(0, -50%)",
    overflowY: "auto",
  };
}

export const Internshipbookmarked = () => {
  const [internshipdata, setinternshipdata] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [internshipIndex, setinternshipIndex] = useState([]);
  const [error, seterror] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { currentUser } = useContext(AuthContext);

  const classes = useStyles();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    axios
      .get(`${bookmark_api}?uid=${currentUser.uid}`)
      .then(async (res) => {
        const list = await res.data.bookmarks;
        if (list.length === 0) {
          setinternshipdata(list);
        } else {
          calculate_days_hours(list);
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error occured while geting bookmark data.", {
          variant: "error",
        });
        seterror(true);
      });
  };

  function calculate_days_hours(list) {
    list.map((d) => {
      const timestampdatetime = d.date_published;
      const date = new Date(timestampdatetime * 1000);
      const timestamp = Date.now();
      var timezone = moment.tz.guess();
      const currentdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(timestamp);
      const timestampdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(date);
      var date_published = moment(timestampdate,'MM/DD/YYYY HH:mm:ss').tz(timezone)
      var currentdatetime = moment(currentdate,'MM/DD/YYYY HH:mm:ss').tz(timezone)

      var duration = moment.duration(
        moment(currentdatetime).diff(moment(date_published))
      );
      var days = duration.asDays();
      var hours = duration.asHours();
      if (days > 1) {
        d.date_published = `${Math.round(days)} days ago`;
      } else {
        d.date_published = `${Math.round(hours)} hours ago`;
      }
      return null;
    });
    setinternshipdata(list);
  }

  const handleOpen = (id) => {
    setOpen(true);
    const query = internshipdata.filter((d) => d.document_id === id);
    setinternshipIndex(query[0]);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const removeSave = (data) => {
    axios
      .get(`${bookmark_api}?uid=${currentUser.uid}`)
      .then((res) => {
        const items = res.data.bookmarks;
        for (let i = 0; i < items.length; i++) {
          if (items[i]["id"] === data.id) {
            axios
              .delete(bookmark_api, {
                data: {
                  uid: currentUser.uid,
                  email: currentUser.email,
                  document_ids: [data.document_id],
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  fetchData();
                  enqueueSnackbar("Bookmark Deleted successfully.", {
                    variant: "success",
                  });
                  handleClose();
                }
              })
              .catch((err) => {
                enqueueSnackbar("Unable to delete bookmark.", {
                  variant: "error",
                });
              });
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error while feteching bookmarks", {
          variant: "error",
        });
      });
  };

  const handleEmail = () => {
    axios
      .post(`${bookmark_api}/email`, {
        uid: currentUser.uid,
      })
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(
            `Your Saved Bookmarks are sent to ${currentUser.email}`,
            {
              variant: "success",
            }
          );
        }
      })
      .catch((err) => {
        enqueueSnackbar("Unable to Send Bookmarks.", {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      <Container className={classes.container}>
        <div className={classes.titlediv}>
          <Typography
            variant="h6"
            color="inherit"
            gutterBottom
            className={classes.title}
          >
            Bookmarked Internships
          </Typography>
          {internshipdata !== null && internshipdata.length > 0 ? (
            <Button
              variant="outlined"
              size="small"
              color="inherit"
              onClick={handleEmail}
            >
              Email Bookmarks
            </Button>
          ) : null}
        </div>
        <div>
          {error ? (
            <Box display="flex" justifyContent="center">
              Unabe to load bookmarked data.
            </Box>
          ) : (
            <div>
              {internshipdata === null || internshipdata.length === 0 ? (
                <p className={classes.bookmarkempty}>
                  You haven't saved any internships.
                </p>
              ) : (
                <Grid
                  container
                  className={classes.root}
                  spacing={0}
                  style={{ oveflow: "auto", position: "relative" }}
                >
                  {internshipdata.map((c) => (
                    <Grid item xs={12} sm={6} md={4} key={c.document_id}>
                      <Card className={classes.root}>
                        <CardActionArea>
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={4} md={4}>
                                <Avatar
                                  alt={c.company_name}
                                  src={c.company_logo}
                                  className={classes.media}
                                  variant="square"
                                  style={{
                                    width: "fit-content",
                                    height: "fit-content",
                                    marginTop: 20,
                                    backgroundColor: "black",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={8} md={8}>
                                <Typography
                                  gutterBottom
                                  variant="body1"
                                  component="h2"
                                >
                                  {c.company_name.substring(0, 13)}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="inherit"
                                  component="p"
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {c.title.substring(0, 15)}
                                </Typography>
                                <Typography
                                  variant="overline"
                                  display="block"
                                  color="inherit"
                                >
                                  {c.company_location}
                                </Typography>
                                <Typography variant="subtitle2" color="inherit">
                                  {c.date_published}
                                </Typography>
                                <Button
                                  style={{
                                    textTransform: "capitalize",
                                    marginTop: 7,
                                  }}
                                  variant="outlined"
                                  onClick={() => handleOpen(c.document_id)}
                                >
                                  Read More
                                </Button>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>
          )}
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <h2 id="simple-modal-title">{internshipIndex.company_name}</h2>
            <Avatar
              alt={internshipIndex.company_name}
              src={internshipIndex.company_logo}
              className={classes.media}
              variant="square"
              style={{
                width: "fit-content",
                height: "fit-content",
                marginTop: 18,
              }}
            />

            <Grid item xs={8} md={8}>
              <Typography
                variant="body2"
                color="inherit"
                style={{
                  fontSize: 18,
                  marginTop: 25,
                }}
              >
                {internshipIndex.company_name}
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                component="h2"
                style={{
                  fontWeight: "bold",
                }}
              >
                {internshipIndex.title}
              </Typography>
              <Typography variant="overline" display="block" color="inherit">
                {internshipIndex.company_location}
              </Typography>
              <Typography variant="subtitle2" color="inherit">
                {internshipIndex.date_published}
              </Typography>
              <Typography variant="subtitle2" color="inherit">
                Intern Responsibilities:
                <ul>
                  {internshipIndex.job_description === null ? null : (
                    <li> {internshipIndex.job_description} </li>
                  )}
                </ul>
              </Typography>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <Button
                  onClick={() =>
                    window.open(internshipIndex.post_url, "_blank")
                  }
                  variant="outlined"
                  color="inherit"
                  size="small"
                >
                  Apply now
                </Button>
                <Button
                  style={{
                    marginLeft: 15,
                  }}
                  variant="outlined"
                  color="inherit"
                  size="small"
                  onClick={() => removeSave(internshipIndex)}
                >
                  <DeleteIcon />
                  unsave
                </Button>
              </div>
            </Grid>
          </div>
        </Modal>
      </Container>
    </React.Fragment>
  );
};
