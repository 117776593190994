import React from 'react';

export const ThankYou = () =>{
    return(
        <React.Fragment>
            <center>
                <label>
                    Thank You for the Purchase
                </label>
            </center>
        </React.Fragment>
      );
};