import React, { useContext } from "react";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Box,
  FormHelperText,
} from "@material-ui/core";
import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { RadioGroup } from "formik-material-ui";
import { AuthContext } from "../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "300px",
  },
  title: {
    "&.MuiFormLabel-root": {
      color: "black",
      fontWeight: "bold",
    },
  },
}));

export const MembershipForm = (props) => {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);

  return (
    <div>
      <Box paddingBottom={2} marginTop={2}>
        <FormControl
          component="fieldset"
          error={props.values.membership === "" ? true : false}
        >
          <FormLabel component="h1" className={classes.title}>
            Membership Type
          </FormLabel>
          <Field component={RadioGroup} name="membership">
            <FormControlLabel
              value="free"
              control={
                <Radio
                  onClick={(e) =>
                    props.setuserData(
                      (prevState) => ({
                        ...prevState,
                        membership: e.target.value,
                      }),
                      localStorage.setItem(
                        "user",
                        JSON.stringify({
                          email: currentUser ? currentUser.email : null,
                          membership: e.target.value,
                        })
                      )
                    )
                  }
                />
              }
              label="Free"
            />
            <FormControlLabel
              value="premium"
              control={
                <Radio
                  onClick={(e) =>
                    props.setuserData(
                      (prevState) => ({
                        ...prevState,
                        membership: e.target.value,
                      }),
                      localStorage.setItem(
                        "user",
                        JSON.stringify({
                          email: currentUser ? currentUser.email : null,
                          membership: e.target.value,
                        })
                      )
                    )
                  }
                />
              }
              label="Premium"
            />
          </Field>
          <FormHelperText>{props.errors.membership}</FormHelperText>
        </FormControl>
      </Box>
    </div>
  );
};
