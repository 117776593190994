import { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Box, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { config } from "../config/constants";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
import { BlogItem } from "../context/BlogLoader";

let link = config.url.API_URL + config.url.BLOG_URL;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    margin: theme.spacing(1),
    height: "230px",
    width: "330px",
  },
  Carousel: {
    padding: theme.spacing(0),
  },
  list: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    "&.MuiTypography-body1": {
      fontWeight: "bold",
      lineHeight: "1.3",
    },
  },
  pos: {
    marginBottom: 12,
    "&.MuiTypography-body2": {
      fontSize: "0.8rem",
    },
  },
  button: {
    borderRadius: 25,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    color: "white",
    backgroundColor: "black",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  paper: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  iframediv: {
    position: "relative",
    paddingBottom: "56.25%" /* 16:9 */,
    marginTop: "35px",
    height: "100%",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: " 100%",
  },
}));

function getModalStyle() {
  return {
    width: "100%",
    maxWidth: "100vw",
    height: "100%",
    position: "fixed",
    top: "50%",
    left: "0",
    transform: "translate(0, -50%)",
    overflowY: "auto",
  };
}

export const BlogCarousel = () => {
  const classes = useStyles();
  const [posts, setposts] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [bloglink, setbloglink] = useState([]);
  const [error, seterror] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (link) => {
    setbloglink(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    await fetch(link)
      .then((response) => response.json())
      .then((res) => {
        const items = res.data;
        setposts(items.slice(0, 5));
        setisLoaded(true);
      })
      .catch((err) => {
        enqueueSnackbar("Error while feteching interns data.", {
          variant: "error",
        });
        seterror(true);
      });
  };

  return (
    <>
      <Container maxWidth={false}>
        <Box marginTop={2}>
          <Typography
            variant="h6"
            color="inherit"
            gutterBottom
            style={{
              fontWeight: "bold",
              paddingLeft: 20,
              paddingTop: 20,
            }}
          >
            <Link underline="none" color="inherit" href="/blog">
              Featured Blogs
            </Link>
          </Typography>
          <div>
            {error ? (
              <Box display="flex" justifyContent="center">
                Unable to load Blog data.
              </Box>
            ) : (
              <div>
                {!isLoaded ? (
                  <BlogItem />
                ) : (
                  <Carousel
                    swipeable={true}
                    responsive={responsive}
                    className={classes.Carousel}
                    removeArrowOnDeviceType={["mobile"]}
                    itemClass={classes.list}
                  >
                    {posts.map(
                      ({
                        id,
                        name,
                        publish_date,
                        post_summary,
                        featured_image,
                        absolute_url,
                      }) => (
                        <Card className={classes.root} key={id}>
                          <Grid container spacing={0}>
                            <Grid item xs={8}>
                              <CardContent>
                                <Typography
                                  className={classes.heading}
                                  component="h6"
                                >
                                  {name}
                                </Typography>
                                <Typography
                                  className={classes.pos}
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {moment({ publish_date }).format(
                                    "MMMM Do YYYY"
                                  )}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  component="p"
                                  color="textPrimary"
                                >
                                  {post_summary
                                    ? post_summary
                                        .replace(/<[^>]+>/g, "")
                                        .slice(0, 50)
                                    : null}
                                  ...
                                  <br />
                                </Typography>
                              </CardContent>
                            </Grid>
                            <Grid item xs={3}>
                              <div style={{ margin: "20px 10px" }}>
                                <CardMedia
                                  component="img"
                                  src={
                                    featured_image ||
                                    "/images/developer-3461405_1280.png"
                                  }
                                  width="fit-content"
                                  height="fit-content"
                                  title={name}
                                />
                                <CardActions
                                  style={{
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link
                                    onClick={() => handleOpen(absolute_url)}
                                    underline="none"
                                    color="inherit"
                                    variant="caption"
                                  >
                                    Read more
                                  </Link>
                                </CardActions>
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      )
                    )}
                    <div>
                      <Button
                        onClick={() => window.open("/blog", "_self")}
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                      >
                        See all
                      </Button>
                    </div>
                  </Carousel>
                )}
              </div>
            )}
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle} className={classes.paper}>
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
              <div className={classes.iframediv}>
                <iframe
                  className={classes.iframe}
                  src={bloglink}
                  title="Iframe Example"
                ></iframe>
              </div>
            </div>
          </Modal>
        </Box>
      </Container>
    </>
  );
};
