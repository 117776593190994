import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  header: {
    marginLeft: 20,
    fontWeight: "bold",
  },
  image: {
    paddingRight: 30,
    minHeight: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
  },
  title: {
    color: "black",
    fontWeight: "900",
    paddingLeft: "20px",
  },
  buttonParent: {
    paddingTop: 10,
    paddingLeft: "20px",
  },
  button: {
    borderRadius: 25,
    paddingLeft: 25,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    color: "white",
    backgroundColor: "black",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  subtitle: {
    paddingLeft: "20px",
  },
}));

export const Featurededucation = () => {
  const classes = useStyles();
  function onclickHandler() {
    document.location = window.location.origin + "/blog";
  }

  return (
    <Container maxWidth={false}>
      <Typography
        variant="h6"
        color="inherit"
        gutterBottom
        className={classes.header}
      >
        Featured Partner
      </Typography>

      <img
        src="https://www.byteacademy.co/hs-fs/hubfs/BYTE%20logo%20files/byte_academy_logo.png?width=2550&height=3300&name=byte_academy_logo.png"
        alt="cur"
        className={classes.image}
      />
      <Typography
        variant="h4"
        component="p"
        align="left"
        className={classes.title}
        onClick={onclickHandler}
      >
        Byte Academy
      </Typography>
      <Typography component="p" align="left" className={classes.subtitle}>
        Education partner
      </Typography>
      <div className={classes.buttonParent}>
        <Button
          onClick={() => window.open("https://www.byteacademy.co/", "_blank")}
          className={classes.button}
          variant="outlined"
          color="inherit"
          size="small"
        >
          Read More
        </Button>
      </div>
    </Container>

    // </div>
  );
};
