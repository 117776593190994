import React, { useState, useContext } from "react";
import {
  Container,
  Grid,
  Box,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { OtherForm } from "./OtherForm";
import { EmailContext } from "../../App";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "100%",
    marginTop: theme.spacing(2),
    "&.MuiButton-containedPrimary": {
      backgroundColor: "black",
      color: "white",
    },
  },
  cardMedia: {
    width: "70px",
    height: "70px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

export const SignInForm = () => {
  const [showMsg, setshowMsg] = useState(false);
  const [moreData, setmoreData] = useState(false);
  const [login, setlogin] = useState(false);
  const [failMsg, setfailMsg] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { setvalue } = useContext(EmailContext);

  const statusSchema = login
    ? yup.object().shape({
        email: yup
          .string()
          .email("Invalid email")
          .required("Email is Required to check the status"),
        password: yup
          .string()
          .required("No password provided.")
          .min(5, "Password is too short - should be 5 chars minimum."),
      })
    : yup.object().shape({
        email: yup
          .string()
          .email("Invalid email")
          .required("Email is Required to check the status"),
      });

  const fieldValues = login
    ? {
        email: "",
        password: "",
      }
    : {
        email: "",
      };

  const checkStatus = (values) => {
    if (values.email === "user1@gmail.com") {
      return history.push("/signup");
    } else if (values.email === "user2@gmail.com") {
      setshowMsg(true);
      setmoreData(false);
      setlogin(false);
    } else if (values.email === "user3@gmail.com") {
      setmoreData(true);
      setshowMsg(false);
      setlogin(false);
    } else if (values.email === "user4@gmail.com") {
      setlogin(true);
      setmoreData(false);
      setshowMsg(false);
    } else {
      return window.location.reload();
    }
  };

  const checkLogin = (values) => {
    if (values.password === "user4567") {
      return history.push("/");
    } else {
      setfailMsg(true);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Grid container justify="center">
          <Grid item xs={10} sm={8} md={4}>
            <Box marginTop={5}>
              {moreData ? (
                <OtherForm />
              ) : (
                <Formik
                  initialValues={{ ...fieldValues }}
                  validationSchema={statusSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (login) {
                      setTimeout(() => {
                        checkLogin(values);
                        setSubmitting(false);
                      }, 300);
                    } else {
                      setTimeout(() => {
                        setvalue(values.email);
                        checkStatus(values);
                        setSubmitting(false);
                      }, 300);
                    }
                  }}
                >
                  {({ values, isSubmitting }) => (
                    <Form className={classes.form}>
                      <CardMedia
                        component="img"
                        src={"/images/nestrialogo.png"}
                        title="Nestria"
                        className={classes.cardMedia}
                      />
                      <Field
                        component={TextField}
                        name="email"
                        type="email"
                        label="Email"
                        variant="outlined"
                        margin="dense"
                        className={classes.field}
                      />
                      {login ? (
                        <Field
                          component={TextField}
                          name="password"
                          label="Password"
                          variant="outlined"
                          margin="dense"
                          className={classes.field}
                        />
                      ) : null}
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        size="medium"
                        className={classes.field}
                      >
                        {login ? "Sign in" : "Check Status"}
                      </Button>
                      {failMsg ? (
                        <Box marginTop={2}>
                          <Typography variant="body2" color="secondary">
                            Wrong password. Try again
                          </Typography>
                        </Box>
                      ) : null}
                    </Form>
                  )}
                </Formik>
              )}
              {showMsg ? (
                <Box marginTop={1} justifyContent="center">
                  <Typography variant="body1" color="inherit">
                    Thanks for your interest! You have not gotten an invite link
                    yet,but we should be sending you one soon!
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
