import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
// import { multiStepContext } from "../context/StepContext";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "formik-material-ui-lab";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "300px",
  },
}));

export const BackgroundInfoForm = (props, formikProps) => {
  const [stack, setstack] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        "https://api.hubapi.com/hubdb/api/v2/tables/3150176/rows?portalId=5340732&Key=Technologies"
      );
      const data = await response.json();
      const teachList = data.objects[0].values[3];
      setstack(teachList);
    }
    fetchData();
  }, []);

  const classes = useStyles();

  return (
    <div>
      <Box paddingBottom={2}>
        <Field
          disabled={false}
          name="current_job_title"
          label="Job Title"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={2}>
        <Field
          disabled={false}
          name="experience"
          type="number"
          label="Experience"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={2}>
        <Field
          disabled={false}
          name="job_industry"
          label="Industry"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
      <Box paddingBottom={2}>
        <Field
          multiple
          name="tech_known"
          disabled={false}
          options={stack}
          getOptionLabel={(option) => option.name}
          component={Autocomplete}
          className={classes.field}
          renderInput={(params) => (
            <Field
              {...params}
              component={TextField}
              name="knowntech"
              variant="outlined"
              margin="dense"
              label="Tech you already know"
            />
          )}
        />
      </Box>
      <Box paddingBottom={2}>
        <Field
          multiple
          name="tech_to_learn"
          disabled={false}
          options={stack}
          getOptionLabel={(option) => option.name}
          component={Autocomplete}
          className={classes.field}
          renderInput={(params) => (
            <Field
              {...params}
              component={TextField}
              name="learntech"
              variant="outlined"
              margin="dense"
              label="Tech you want to learn"
            />
          )}
        />
      </Box>
      <Box paddingBottom={2}>
        <Field
          disabled={false}
          name="ideal_job_role"
          label="Ideal job role"
          margin="dense"
          variant="outlined"
          component={TextField}
          className={classes.field}
        />
      </Box>
    </div>
  );
};
