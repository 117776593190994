const FORM_DATA_MAP = {
  firstname: "fname",
  lastname: "lname",
  phoneno: "phone",
  email: "email",
};
const FORM_SUBMISSION_URL = "http://localhost:1337/forms/submit";
/**
 * This function looks for a Hubspot cookie on the page
 * and extracts the unique user token from it.
 */
function getHutk() {
  return new Promise((resolve, reject) => {
    let currentCookie = document.cookie;
    if (currentCookie.search("hubspotutk") !== -1) {
      // Hubspot cookie found
      // Extract the User Token
      let hubspotCookieIndex = currentCookie.search("hubspotutk");
      try {
        let hutk = currentCookie
          .slice(hubspotCookieIndex)
          .split(";")[0]
          .split("=")[1];
        resolve(hutk);
      } catch (err) {
        reject(err);
      }
    } else {
      reject();
    }
  });
}

/**
 * This function collects and packages the context data
 * required to connect the user data with their
 * hubspot web analytics data
 * using hubspot user token
 */
function getContextData() {
  return new Promise(async (resolve, reject) => {
    let hubspot_user_token = await getHutk().catch((err) => {
      reject(err);
    });
    let context_payload = {
      hubspot_user_token: hubspot_user_token,
      page_url: document.documentURI,
      page_name: document.title,
    };
    resolve(context_payload);
  });
}

function packageFormData(rawFormData) {
  return new Promise((resolve, reject) => {
    let keys = Object.keys(rawFormData);
    let referenceKeys = Object.keys(FORM_DATA_MAP);
    let finalPackage = {};
    let allPromises = keys.map((key) => {
      if (referenceKeys.indexOf(key) !== -1) {
        finalPackage[FORM_DATA_MAP[key]] = rawFormData[key];
      }
      // TODO: Initially testing with few fields
      // else{
      // 	finalPackage[key] = rawFormData[key];
      // }
      return null;
    });
    Promise.all(allPromises)
      .then(() => {
        resolve(finalPackage);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function postForm(formData) {
  console.log("Form Data Received!");
  let contextData = await getContextData();
  let payload = {
    formData: await packageFormData(formData),
    context: contextData,
  };
  console.log(payload);
  let requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  fetch(FORM_SUBMISSION_URL, requestOptions)
    .then((res) => {
      console.log("Response: ");
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
}

const hubspot = {
  postFormData: postForm,
};

export default hubspot;
