import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Grid, Button, Avatar } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useState, useEffect } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";

const data = [
  {
    name: "Web Development Workshop",
    image: "https://miro.medium.com/max/700/1*zJkojKNpFD9HFGPJLCs15Q.jpeg",
    //lh3.googleusercontent.com/proxy/5DKXEthU1w7kbmbcrEQWRKFRlavta0xGcMzG7O12VRd3wTM_n-ruoBUyFvnPLGLa7VLVRpKe5MA9rbjNdTQBRHOn0CN-wuElXl3dvyS5RTH9Ap9KeyUsW9bv0L09dsmmkQ",
    content: "Html/Css,Bootstrap,Wordpress",
    date: "1611210600",
    end_session: "1611217800",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Python Workshop",
    image:
      "https://symbols.getvecta.com/stencil_92/75_python-vertical.6c7f1f8721.svg",
    content: "Basic Python",
    date: "1611826200",
    end_session: "1611833400",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "React.js Workshop",
    image:
      "https://seeklogo.com/images/R/react-logo-7B3CE81517-seeklogo.com.png",
    content: "React.js",
    date: "1611311400",
    end_session: "1611315000",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Vue.js Workshop",
    image:
      "https://image.slidesharecdn.com/vuejsnew-180820072551/95/vuejs-workshop-2-638.jpg?cb=1534750615",
    content: "Vue.js",
    date: "1611221400",
    end_session: "1611228600",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Django Workshop",
    image:
      "https://blog.pusher.com/wp-content/uploads/2018/03/the-definitive-django-and-pusher-online-workshop-header.png",
    content: "Django framework",
    date: "1612179000",
    end_session: "1612186200",
    location: "Chicago",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Flask Workshop",
    image:
      "https://storage.googleapis.com/ehimages/2018/10/1/img_1bc4d76b6563bd4688276ccbf5047e95_1538380835324_processed_original.jpg",
    content: "Flask",
    date: "1611135000",
    end_session: "1611138600",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "AWS Workshop",
    image: "https://miro.medium.com/max/1200/1*WwcSmLQHxEt4STfu3bXN7w.png",
    content: "AWS",
    date: "1611120600",
    end_session: "1611132300",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Node.js Workshop",
    image: "https://i.ytimg.com/vi/U8XF6AFGqlc/maxresdefault.jpg",
    content: "Node",
    date: "1611484200",
    end_session: "1611491400",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Nuxt.js Workshop",
    image:
      "https://image.slidesharecdn.com/nuxtjs-161214153928/95/nuxtjs-introduction-1-638.jpg",
    content: "Node",
    date: "1611138600",
    end_session: "1611145800",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Strapi Workshop",
    image:
      "https://cdn-images-1.medium.com/max/1200/1*M79qVYy7vnpSolBHoEulTg.jpeg",
    content: "Strapi",
    date: "1611577800",
    end_session: "1611586800",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
  {
    name: "Bootstrap Workshop",
    image: "https://www.drupal.org/files/project-images/bootstrap-stack.png",
    content: "Bootstrap",
    date: "1611667800",
    end_session: "1611673200",
    location: "Bangalore",
    duration: "4",
    Fee: "$25",
    Presenter_name: "Greg Smith",
  },
];
const datefilter = [
  "Today",
  "Next Day",
  "Next 3 Days",
  "Next 7 Days",
  "Next 15 Days",
  "Next 30 Days",
];
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: "none",
    background: "rgb(250, 250, 250)",
    maxHeight: "600px",
    position: "relative",
    overflow: "auto",
    margin: "8px",
  },
  container: {
    marginTop: "10px",
  },
  detail: {
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}));
function getModalStyle() {
  return {
    width: "100%",
    maxWidth: "100vw",
    maxHeight: "100%",
    position: "fixed",
    top: "50%",
    left: "0",
    transform: "translate(0, -50%)",
    overflowY: "auto",
  };
}

export const WorkshopList = () => {
  const classes = useStyles();
  const [dataa, setData] = useState([]);
  const [caldata, calculatedData] = useState([]);
  const [date, setDate] = useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [workshopIndex, setworkshopIndex] = React.useState([]);
  const [isLive, setisLive] = useState(null);

  useEffect(() => {
    calculate_days_hours();
  }, []);
  const checklive = (data) => {
    const timestampdatetime = data.date;
    const date = new Date(timestampdatetime * 1000);
    const end_session1 = new Date(data.end_session * 1000);
    const timestamp = Date.now();
    var timezone = moment.tz.guess();
    const currentdate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(timestamp);
    const timestampdate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
    const e_s = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(end_session1);
    const time_live = moment(timestampdate,'MM/DD/YYYY HH:mm:ss').tz(timezone)
    const current_time = moment(currentdate,'MM/DD/YYYY HH:mm:ss').tz(timezone)
    const es = moment(e_s,'MM/DD/YYYY HH:mm:ss').tz(timezone)

    if (moment(current_time).isBetween(time_live, es)) {
      setisLive(true);
    } else {
      setisLive(false);
    }
  };
  const handleOpen = (name) => {
    setOpen(true);
    const query = data.filter((d) => d.name === name);
    setworkshopIndex(query[0]);
    if (query[0] !== null || query[0] !== []) {
      checklive(query[0]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  function calculate_days_hours() {
    const arr = [];
    data.map((d) => {
      d.date2 = d.date;
      const date = new Date(parseInt(d.date) * 1000);
      const es = new Date(parseInt(d.end_session) * 1000);
      const timestamp = Date.now();
      var timezone = moment.tz.guess();
      const currentdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(timestamp);

      const timestampdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(date);
      const esdate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(es);
      var date_published = moment(timestampdate).tz(timezone).format();
      var currentdatetime = moment(currentdate).tz(timezone).format();
      var es_datetime = moment(esdate).tz(timezone).format();
      d.date_published = date_published;
      d.es_datetime = es_datetime;
      var duration = moment.duration(
        moment(es_datetime).diff(moment(currentdatetime))
      );
      var days = duration.asDays();
      var hours = duration.asHours();

      if (new Date(timestampdate).getMinutes() === 0) {
        d.date1 = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          weekday: "long",
        }).format(date);
      } else {
        d.date1 = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          weekday: "long",
        }).format(date);
      }
      if (Math.round(days) > 1) {
        arr.push(d);
        d.date3 = `${Math.round(days)} days`;
      } else if (Math.round(days) === 1) {
        arr.push(d);
        d.date3 = `${Math.round(days)} day`;
      } else if (Math.round(hours) > 1 && Math.round(hours) <= 24) {
        arr.push(d);
        d.date3 = `${Math.round(hours)} hours`;
      } else if (Math.round(hours) === 1) {
        arr.push(d);
        d.date3 = `${Math.round(hours)} hour`;
      }
      return null;
    });

    const arr1 = arr.sort((a, b) => a.date2 - b.date2);
    calculatedData(arr1);
    setData(arr);
  }

  const getfiltereddateData = (event) => {
    const d = event.target.value;
    setDate(event.target.value);
    const arr = [];
    if (d === "Next Day") {
      const d = caldata.filter(
        (d) => d["date3"].includes("day") && d["date3"].split(" ")[0] === 1
      ).length;
      if (d === 0) {
        setData(arr);
      } else {
        caldata
          .filter(
            (d) => d["date3"].includes("day") && d["date3"].split(" ")[0] === 1
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    } else if (d === "Total Workshop") {
      setData(caldata);
    } else if (d === "Today") {
      const d = caldata.filter(
        (d) => d["date3"].includes("hours") || d["date3"].includes("hour")
      ).length;

      if (d === 0) {
        setData(arr);
      } else {
        caldata
          .filter(
            (d) => d["date3"].includes("hours") || d["date3"].includes("hour")
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    } else if (d === "Next 3 Days") {
      const d = caldata.filter(
        (d) =>
          (d["date3"].includes("days") || d["date3"].includes("day")) &&
          d["date3"].split(" ")[0] >= 1 &&
          d["date3"].split(" ")[0] <= 3
      ).length;

      if (d === 0) {
        setData(arr);
      } else {
        caldata
          .filter(
            (d) =>
              (d["date3"].includes("days") || d["date3"].includes("day")) &&
              d["date3"].split(" ")[0] >= 1 &&
              d["date3"].split(" ")[0] <= 3
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    } else if (d === "Next 7 Days") {
      const d = caldata.filter(
        (d) =>
          (d["date3"].includes("days") || d["date3"].includes("day")) &&
          d["date3"].split(" ")[0] >= 1 &&
          d["date3"].split(" ")[0] <= 7
      ).length;

      if (d === 0) {
        setData(arr);
      } else {
        caldata
          .filter(
            (d) =>
              (d["date3"].includes("days") || d["date3"].includes("day")) &&
              d["date3"].split(" ")[0] >= 1 &&
              d["date3"].split(" ")[0] <= 7
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    } else if (d === "Next 15 Days") {
      const d = caldata.filter(
        (d) =>
          (d["date3"].includes("days") || d["date3"].includes("day")) &&
          d["date3"].split(" ")[0] >= 1 &&
          d["date3"].split(" ")[0] <= 14
      ).length;

      if (d === 0) {
        setData(arr);
      } else {
        caldata
          .filter(
            (d) =>
              (d["date3"].includes("days") || d["date3"].includes("day")) &&
              d["date3"].split(" ")[0] >= 1 &&
              d["date3"].split(" ")[0] <= 14
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    } else if (d === "Next 30 Days") {
      const d = caldata.filter(
        (d) =>
          (d["date3"].includes("days") || d["date3"].includes("day")) &&
          d["date3"].split(" ")[0] >= 1 &&
          d["date3"].split(" ")[0] <= 30
      ).length;

      if (d === 0) {
        setData(arr);
      } else {
        caldata
          .filter(
            (d) =>
              (d["date3"].includes("days") || d["date3"].includes("day")) &&
              d["date3"].split(" ")[0] >= 1 &&
              d["date3"].split(" ")[0] <= 30
          )
          .map((filteredData) => {
            arr.push(filteredData);
            setData(arr);
            return null;
          });
      }
    }
  };
  return (
    <Container className={classes.container}>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="demo-controlled-open-select-label"
          style={{ marginLeft: 15, fontSize: 13 }}
        >
          Select Date Filter
        </InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          input={<Input />}
          style={{
            marginLeft: 15,
            maxWidth: 125,
            minWidth: 150,
            marginBottom: 5,
            fontSize: 12,
          }}
          value={date}
          onChange={getfiltereddateData}
        >
          <MenuItem value="Total Workshop">
            <span>
              Total Workshop
              <span style={{ color: "rgb(133, 140, 148)" }}>
                ({caldata.length})
              </span>
            </span>
          </MenuItem>
          {datefilter.map((c, index) => (
            <MenuItem key={index} value={c}>
              <span>
                {c}
                <span> </span>
                {c === "Today" && (
                  <span style={{ color: "rgb(133, 140, 148)" }}>
                    (
                    {
                      caldata.filter(
                        (d) =>
                          d["date3"].includes("hours") ||
                          d["date3"].includes("hour")
                      ).length
                    }
                    )
                  </span>
                )}
                <span>
                  {c === "Next Day" && (
                    <span style={{ color: "rgb(133, 140, 148)" }}>
                      (
                      {
                        caldata.filter(
                          (d) =>
                            d["date3"].includes("day") &&
                            d["date3"].split(" ")[0] === 1
                        ).length
                      }
                      )
                    </span>
                  )}
                </span>
                <span>
                  {c === "Next 3 Days" && (
                    <span style={{ color: "rgb(133, 140, 148)" }}>
                      (
                      {
                        caldata.filter(
                          (d) =>
                            (d["date3"].includes("days") ||
                              d["date3"].includes("day")) &&
                            d["date3"].split(" ")[0] >= 1 &&
                            d["date3"].split(" ")[0] <= 3
                        ).length
                      }
                      )
                    </span>
                  )}
                </span>
                <span>
                  {c === "Next 7 Days" && (
                    <span style={{ color: "rgb(133, 140, 148)" }}>
                      (
                      {
                        caldata.filter(
                          (d) =>
                            (d["date3"].includes("days") ||
                              d["date3"].includes("day")) &&
                            d["date3"].split(" ")[0] >= 1 &&
                            d["date3"].split(" ")[0] <= 7
                        ).length
                      }
                      )
                    </span>
                  )}
                </span>
                <span>
                  {c === "Next 15 Days" && (
                    <span style={{ color: "rgb(133, 140, 148)" }}>
                      (
                      {
                        caldata.filter(
                          (d) =>
                            (d["date3"].includes("days") ||
                              d["date3"].includes("day")) &&
                            d["date3"].split(" ")[0] >= 1 &&
                            d["date3"].split(" ")[0] <= 15
                        ).length
                      }
                      )
                    </span>
                  )}
                </span>
                <span>
                  {c === "Next 30 Days" && (
                    <span style={{ color: "rgb(133, 140, 148)" }}>
                      (
                      {
                        caldata.filter(
                          (d) =>
                            (d["date3"].includes("days") ||
                              d["date3"].includes("day")) &&
                            d["date3"].split(" ")[0] >= 1 &&
                            d["date3"].split(" ")[0] <= 30
                        ).length
                      }
                      )
                    </span>
                  )}
                </span>
              </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid
        container
        spacing={0}
        className={classes.root}
        style={{ oveflow: "auto", position: "relative" }}
      >
        {dataa.length === 0 || dataa === null ? (
          <p style={{ marginLeft: "auto", marginRight: "auto" }}>
            Result Not Found
          </p>
        ) : (
          dataa.map((c, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={4} md={4}>
                        <Avatar
                          alt={c.name}
                          src={c.image}
                          className={classes.media}
                          variant="square"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                            marginTop: 6,
                          }}
                        />
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <Typography
                          variant="body1"
                          color="inherit"
                          component="h2"
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                        >
                          {c.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="inherit"
                          className={classes.detail}
                        >
                          {c.Presenter_name}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="inherit"
                          className={classes.detail}
                        >
                          Sessions : {c.duration}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="inherit"
                          className={classes.detail}
                        >
                          Start Session :
                          {moment(c.date_published,'MM/DD/YYYY HH:mm:ss').format("LLL")}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="inherit"
                          className={classes.detail}
                        >
                          End Session :{moment(c.es_datetime,'MM/DD/YYYY HH:mm:ss').format("LLL")}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="inherit"
                          className={classes.detail}
                        >
                          Fee : {c.Fee}
                        </Typography>
                        <Button
                          style={{}}
                          variant="outlined"
                          onClick={() => handleOpen(c.name)}
                        >
                          Read More
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />{" "}
          </IconButton>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4}>
              <Avatar
                alt={workshopIndex.name}
                src={workshopIndex.image}
                className={classes.media}
                variant="square"
                style={{
                  width: "fit-content",
                  height: "fit-content",
                  marginTop: 6,
                }}
              />
            </Grid>
            <Grid item xs={8} md={8}>
              <Typography
                variant="body1"
                color="inherit"
                component="h2"
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                {workshopIndex.name}
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                className={classes.detail}
              >
                {workshopIndex.Presenter_name}
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                className={classes.detail}
              >
                Sessions : {workshopIndex.duration}
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                className={classes.detail}
              >
                Start Session :
                {moment(workshopIndex.date_published,'MM/DD/YYYY HH:mm:ss').format("LLL")}
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                className={classes.detail}
              >
                End Session :{moment(workshopIndex.es_datetime,'MM/DD/YYYY HH:mm:ss').format("LLL")}
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                className={classes.detail}
              >
                Fee : {workshopIndex.Fee}
              </Typography>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <Button
                  onClick={() =>
                    window.open("https://www.byteacademy.co/", "_blank")
                  }
                  // className={classes.button}
                  variant="outlined"
                  color="inherit"
                  size="small"
                >
                  Register
                </Button>
                {isLive ? (
                  <>
                    <Button
                      style={{
                        marginLeft: 15,
                      }}
                      color="inherit"
                      variant="outlined"
                      size="small"
                      onClick={(e) => e.preventDefault()}
                    >
                      Join now
                    </Button>
                  </>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </Container>
  );
};
